import React from 'react'
import Logo from '../../img/Logo.png'


function Template(props) {
   
    return (
        <div className='template' id="template">
            
            <div className="template__head">
                <img src={Logo} alt="Logo of Company" />
                <div className="template__head__details">
                    <p>{props.data.joined.substr(0, 16)}</p>
                </div>
            </div>

            <h2>Registration Form</h2>

            <div className="template__body">

                <div className="template__body__section">
                    <div className="template__body__head">
                        Personal Information
                    </div>
                    <div className="templae__body__container">
                        <div className="template__body__col12">

                            <ViewItem
                                label="Title"
                                value={props.data.title}
                                name="title"
                                
                            />

                            <ViewItem
                                label="Gender"
                                value={props.data.gender}
                                name="gender"
                                
                            />

                        </div>
                        <div className="template__body__col12">

                            <ViewItem
                                label="Date Of Birth"
                                value={(props.data.dob).toDateString()}
                                name="dob"
                                
                            />

                            <ViewItem
                                label="First Name"
                                value={props.data.firstName}
                                name="firstName"
                                
                            />

                        </div>
                        <div className="template__body__col12">

                            <ViewItem
                                label="Middle Name"
                                value={props.data.middleName}
                                name="middleName"
                                
                            />

                            <ViewItem
                                label="Last Name"
                                value={props.data.lastName}
                                name="lastName"
                                
                            />

                        </div>
                        <div className="template__body__col12">

                            <ViewItem
                                label="Ghana Card Number"
                                value={props.data.ghanaCardNo}
                                name="ghanaCardNo"
                                
                            />

                            <ViewItem
                                label="Country Of Citizenship"
                                value={props.data.nationalities}
                                name="nationalities"
                                
                            />

                        </div>
                        <div className="template__body__col12">
                            <ViewItem
                                label="Country of Residence"
                                value={props.data.residentCountry}
                                name="residentCountry"
                                
                            />

                            <ViewItem
                                label="Do you have Residence Work Permit?"
                                value={props.data.residencePermit}
                                name="residencePermit"
                               
                            />
                        </div>

                        <div className="template__body__col12">
                            <ViewItem
                                label="Resident Work Permit Number"
                                value={props.data.residencePermitNo}
                                name="residencePermitNo"
                                
                            />
                        </div>
                    </div>
                </div>

                <div className="template__body__section">
                    <div className="template__body__head">
                        Contact Information
                    </div>
                    <div className="templae__body__container">
                        <div className="template__body__col12">

                            <ViewItem
                                label="Residential Address"
                                value={props.data.residentialAddress}
                                name="residentialAddress"
                                
                            />



                        </div>
                        <div className="template__body__col12">

                            <ViewItem
                                label="Region"
                                value={props.data.region}
                                name="region"
                                
                            />

                            <ViewItem
                                label="Ghana Post Address"
                                value={props.data.ghanaPostAddress}
                                name="ghanaPostAddress"
                                
                            />

                        </div>
                        <div className="template__body__col12">

                            <ViewItem
                                label="District"
                                value={props.data.district}
                                name="district"
                                
                            />

                            <ViewItem
                                label="Postal Address"
                                value={props.data.postalAddress}
                                name="postalAddress"
                               
                            />

                        </div>
                        <div className="template__body__col12">

                            <ViewItem
                                label="Email Address"
                                value={props.data.email}
                                name="email"
                                
                            />

                            <ViewItem
                                label="Landline Number"
                                value={props.data.landlineNo}
                                name="landlineNo"
                                
                            />

                        </div>
                        <div className="template__body__col12">

                            <ViewItem
                                label="Mobile Number"
                                value={props.data.mobileNo}
                                name="mobileNo"
                                
                            />

                            <ViewItem
                                label="Is mobile linked to your Ghana Card ?"
                                value={props.data.mobileLinkedToCard}
                                name="mobileLinkedToCard"
                                
                            />

                        </div>

                    </div>
                </div>

                <div className="template__body__section">
                    <div className="template__body__head">
                        Category Information
                    </div>
                    <div className="templae__body__container">
                        <div className="template__body__col12">

                            <ViewItem
                                label="Professional category"
                                value={props.data.professionalCategory}
                                name="professionalCategory"
                                
                            />

                        </div>
                        {
                            props.data.professionalCategory === "Real Estate Agent" &&

                            <>
                                <div className="template__body__col12">

                                    <ViewItem
                                        label="Name of affiliate"
                                        value={props.data.agent.agentName}
                                        name="agentName"
                                       
                                    />

                                    <ViewItem
                                        label="Licence number of affiliate"
                                        value={props.data.agent.agentLicence}
                                        name="agentLicence"
                                       
                                    />

                                </div>

                                <div className="template__body__col12">

                                    <ViewItem
                                        label="Business/Company Name of Broker"
                                        value={props.data.agent.agentBusiness}
                                        name="agentBusiness"
                                        
                                    />

                                    <ViewItem
                                        label="Email address of the Broker"
                                        value={props.data.agent.agentEmail}
                                        name="agentEmail"
                                        
                                    />

                                </div>

                                <div className="template__body__col12">

                                    <ViewItem
                                        label="Business address of the Broker(GPS)"
                                        value={props.data.agent.agentAddress}
                                        name="agentAddress"
                                       
                                    />

                                    <ViewItem
                                        label="Phone Number of the Broker"
                                        value={props.data.agent.agentPhone}
                                        name="agentPhone"
                                        
                                    />

                                </div>
                            </>
                        }
                        <div className="template__body__col12">

                            <ViewItem
                                label="Specialisation"
                                value={props.data.specialisation}
                                name="specialisation"
                                
                            />

                            <ViewItem
                                label="Qualification"
                                value={props.data.qualification}
                                name="qualification"
                               
                            />

                        </div>

                        {
                            props.data.qualification === "I am a valuer licenced by GhIS" &&
                            <div className="template__body__col12">

                                <ViewItem
                                    label="Diploma Number"
                                    value={props.data.GhISDiplomaNo}
                                    name="GhISDiplomaNo"
                                    
                                />

                                <ViewItem
                                    label="Date Of Issue"
                                    value={(props.data.GhISDateOfIssue).toDateString()}
                                    name="GhISDateOfIssue"
                                    
                                />


                            </div>

                        }

                        {
                            props.data.qualification === "I am a lawyer licenced by GLC" &&
                            <div className="template__body__col12">

                                <ViewItem
                                    label="Licence Number"
                                    value={props.data.GLCLicenceNo}
                                    name="GLCLicenceNo"
                                    
                                />

                                <ViewItem
                                    label="Date Of Issue"
                                    value={(props.data.GLCDateOfIssue).toDateString()}
                                    name="GLCDateOfIssue"
                                    
                                />


                            </div>

                        }

                        {
                            props.data.qualification === "I am a real estate agency practitioner with prior experience" &&
                            <div className="template__body__col12">

                                <ViewItem
                                    label="Years of experience"
                                    value={props.data.experience}
                                    name="experience"
                                    
                                />


                            </div>

                        }

                        {
                            props.data.qualification === "I am a non-resident practitioner" &&
                            <>
                                <div className="template__body__col12">

                                    <ViewItem
                                        label="Country Of Practice"
                                        value={props.data.NRP.NRPCountry}
                                        name="NRPCountry"
                                       
                                    />

                                    <ViewItem
                                        label="Institution regulating/Issuing licence"
                                        value={props.data.NRP.NRPRegulator}
                                        name="NRPRegulator"
                                        
                                    />
                                </div>

                                <div className="template__body__col12">

                                    <ViewItem
                                        label="Licence Number"
                                        value={props.data.NRP.NRPLicenceNo}
                                        name="NRPLicenceNo"
                                       
                                    />

                                    <ViewItem
                                        label="Date Of Issue"
                                        value={(props.data.NRPDateOfIssue).toDateString()}
                                        name="NRPDateOfIssue"
                                        
                                    />

                                </div>

                            </>

                        }
                        <div className="template__body__col12">

                            <ViewItem
                                label="Mode of operation"
                                value={props.data.operation}
                                name="operation"
                                
                            />
                        </div>

                        {

                            props.data.operation === "Company or Partnership" &&
                            <div className="template__body__col12">
                                <ViewItem
                                    label="Designated Officer"
                                    value={props.data.designatedOfficer}
                                    name="designatedOfficer"
                                    
                                />
                            </div>
                        }

                        <div className="template__body__col12">

                            <ViewItem
                                label="Full time or part time"
                                value={props.data.workTime}
                                name="workTime"
                                
                            />

                        </div>

                        {
                            props.data.workTime === "Part Time" &&
                            <div className="template__body__col12">

                                <ViewItem
                                    label="No Days user works in a week"
                                    value={props.data.partTime.partTimeNoOfDays}
                                    name="partTimeNoOfDays"
                                    
                                />

                                <ViewItem
                                    label="Days user works in a week"
                                    value={props.data.partTime.partTimeDays.join(", ")}
                                    name="partTimeDays"
                                    
                                />
                            </div>

                        }

                        <div className="template__body__col12">
                            <ViewItem
                                label="Other Employment"
                                value={props.data.currentEmployment}
                                name="currentEmployment"
                               
                            />
                        </div>

                        {
                            props.data.currentEmployment === "YES" &&
                            <div className="template__body__col12">
                                <ViewItem
                                    label="Other Employment Details"
                                    value={props.data.currentEmploymentName}
                                    name="currentEmploymentName"
                                    
                                />
                            </div>
                        }

                    </div>
                </div>

                <div className="template__body__section">
                    <div className="template__body__head">
                        Office Information
                    </div>
                    <div className="templae__body__container">
                        <div className="template__body__col12">

                            <ViewItem
                                label="Office Physical address"
                                value={props.data.companyAddress}
                                name="companyAddress"
                                
                            />



                            <ViewItem
                                label="Office Postal address"
                                value={props.data.companyPostalAddress}
                                name="companyPostalAddress"
                                
                            />



                        </div>

                        <div className="template__body__col12">

                            <ViewItem
                                label="Office Email address"
                                value={props.data.companyEmail}
                                name="companyEmail"
                                
                            />

                            <ViewItem
                                label="Office telephone number"
                                value={props.data.companyTelephone}
                                name="companyTelephone"
                                
                            />

                        </div>

                        {
                            props.data.professionalCategory === "Real Estate Broker" &&
                            <div className="template__body__col12">
                                <ViewItem
                                    label="Company Regions"
                                    value={props.data.companyRegions}
                                    name="companyRegions"
                                    
                                />
                            </div>
                        }


                    </div>
                </div>

                <div className="template__body__section">
                    <div className="template__body__head">
                        Criminal History
                    </div>
                    <div className="templae__body__container">
                        <div className="template__body__col12">
                            <ViewItem
                                label="Threat activities"
                                value={props.data.threat}
                                name="threat"
                                
                            />
                        </div>

                        {
                            props.data.threat === "YES" &&
                            <div className="template__body__col12">
                                <ViewItem
                                    label="Threat Description"
                                    value={props.data.threatDesc}
                                    name="threatDesc"
                                    
                                />
                            </div>
                        }

                        <div className="template__body__col12">
                            <ViewItem
                                label="Fraud Activities"
                                value={props.data.fraud}
                                name="fraud"
                                
                            />
                        </div>

                        {
                            props.data.fraud === "YES" &&
                            <div className="template__body__col12">
                                <ViewItem
                                    label="Fraud Description"
                                    value={props.data.fraudDesc}
                                    name="fraudDesc"
                                    
                                />
                            </div>
                        }

                        <div className="template__body__col12">
                            <ViewItem
                                label="Crime Acivities"
                                value={props.data.crime}
                                name="crime"
                               
                            />
                        </div>

                        {
                            props.data.crime === "YES" &&
                            <div className="template__body__col12">
                                <ViewItem
                                    label="Crime Description"
                                    value={props.data.crimeDesc}
                                    name="crimeDesc"
                                    
                                />
                            </div>
                        }

                        <div className="template__body__col12">
                            <ViewItem
                                label="Licence Revokation"
                                value={props.data.revokation}
                                name="revokation"
                                
                            />
                        </div>

                        {
                            props.data.revokation === "YES" &&
                            <div className="template__body__col12">
                                <ViewItem
                                    label="Revokation Description"
                                    value={props.data.revokationDesc}
                                    name="revokationDesc"
                                    
                                />
                            </div>
                        }

                        <div className="template__body__col12">
                            <ViewItem
                                label="Pending Charges"
                                value={props.data.charges}
                                name="charges"
                                
                            />
                        </div>

                        {
                            props.data.charges === "YES" &&
                            <div className="template__body__col12">
                                <ViewItem
                                    label="Pending Charges Description"
                                    value={props.data.chargesDesc}
                                    name="chargesDesc"
                                    
                                />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Template

function ViewItem(props) {

    const handleSetModal = () => {
        props.setModalDetails({
            label: props.label,
            value: props.value,
            name: props.name,
            type: '',
            list: []
        })
        props.showModal(true)
    }

    return (
        <div
            className='template__item'
            onClick={() => handleSetModal()}
        >
            <h4>{props.label}</h4>
            <div className="template__item__value">
                {props.value}
            </div>
        </div>
    )
}