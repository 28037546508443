import React from 'react'
import { SyncLoader } from 'react-spinners';
import { isMobile } from 'react-device-detect'; 
function Spinner(props) {

  return (
    <div class="spinner" style={props.showSpinner ? {height: props.height || '70vh', width: props.width - 40 || '100%'} : { height: '0' }}>
      <SyncLoader color='#333399' loading={props.showSpinner} size={props.small ? props.width/6 : isMobile ? 60 : 90} margin={10} />
    </div>
  )
}

export default Spinner