import React from 'react'

import { BsCalendarDateFill, BsFillTelephoneFill, BsFillPinMapFill } from "react-icons/bs";
import { IoIosBusiness, IoIosMail } from "react-icons/io";
import { FaLocationArrow, FaFileContract, FaFlag } from "react-icons/fa";

import { GiPostOffice } from "react-icons/gi";

import { TbNumber } from "react-icons/tb";
import { HiUserGroup } from "react-icons/hi";
import { COUNTRIES } from '../shared/Constants';
import { Input, Select, InputArea, DateInput } from '../shared/Inputs';

function CompanyInfo(props) {
    return (
        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>
            {
            /*
            
            <Input
                id="registeredName"
                label="Registered name of the company"
                value={props.registeredName}
                setValue={props.setRegisteredName}
                placeholder="Enter Registered name"
            >
                <IoIosBusiness />
            </Input>

            <Input
                id="registrationNumber"
                label="Registration Number of the company"
                value={props.registrationNumber}
                setValue={props.setRegistrationNumber}
                placeholder="Enter Registration Number"
            >
                <FaFileContract />
            </Input>

            <DateInput
                id="dateOfInc"
                label="Date of incorporation"
                value={props.dateOfInc}
                setValue={props.setDateOfInc}

            >
                <BsCalendarDateFill />
            </DateInput>

            <Select
                id="countryOfInc"
                label="Country Of Incorporation"
                value={props.countryOfInc}
                setValue={props.setCountryOfInc}
                placeholder="Select Country of Incorporation"
                list={COUNTRIES}
            >
                <FaFlag />
            </Select>

            <Input
                id="tinNo"
                label="TIN number"
                value={props.tinNo}
                setValue={props.setTinNo}
                placeholder="Enter TIN Number"
            >
                <TbNumber />
            </Input>

            <InputArea
                id="companyDirectors"
                label="Directors, Members or Partners(Name & Designation)"
                value={props.companyDirectors}
                setValue={props.setCompanyDirectors}
                placeholder="Directors, Members or Partners
                        Name and designation*
                        "
            >
                <HiUserGroup />
            </InputArea>

            */}


            <Input
                id="companyAddress"
                label="Physical address of your office with GPS"
                value={props.companyAddress}
                setValue={props.setCompanyAddress}
                placeholder="Physical address of your office with GPS"
            >
                <FaLocationArrow />
            </Input>

            <Input
                id="companyPostalAddress"
                label="Postal address of your office"
                value={props.companyPostalAddress}
                setValue={props.setCompanyPostalAddress}
                placeholder="Enter Postal address of your office"
            >
                <GiPostOffice />
            </Input>

            <Input
                id="companyEmail"
                label="Office Email address"
                value={props.companyEmail}
                setValue={props.setCompanyEmail}
                placeholder="Enter Email address of your office"
            >
                <IoIosMail />
            </Input>

            <Input
                id="companyTelephone"
                label="Office telephone number"
                value={props.companyTelephone}
                setValue={props.setCompanyTelephone}
                placeholder="Enter office telephone number"
                checktype="number"
            >
                <BsFillTelephoneFill />
            </Input>

            {
                props.professionalCategory === "Real Estate Broker" &&
                <InputArea
                    id="companyRegions"
                    label="Regions of operation (Address and Contact)"
                    value={props.companyRegions}
                    setValue={props.setCompanyRegions}
                    placeholder="Regions of operation (Address and Contact)

                    Provide the address of the office(s) in the selected region(s)*
                    Provide the email address of each office*
                    Provide the telephone number(s) of each office*
                    Designated officers of the branch office(s)
                    "
                >
                    <BsFillPinMapFill />
                </InputArea>
            }

        </form>
    )
}

export default CompanyInfo