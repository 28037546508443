import React, { useState, useEffect } from 'react'
import Regulate from '../../img/reacbylicence.png';
import Logo from '../../img/Logo.png'
import TestImg from '../../img/1517556439081.jpg'
import { useParams } from 'react-router-dom';
import { getLicence } from './dbFunctions';
import { Spinner, Footer } from '../shared';

const inlineStyle = {
    color: 'red',
    border: '2px solid red'
}

function LicenceDoc() {

    const { id } = useParams();

    //console.log(id);

    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getUserDetails()
    }, [])

    async function getUserDetails() {
        const results = await getLicence(id)
        setUser(results)
        setLoading(false)
    }

    function formatDate(dateString) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    return (
        loading ?
            <div className='licencedoc__spinner'>
                <Spinner showSpinner={loading} />

            </div>
            :
            <div className="licencedoc__container">
                <h2>Real Estate Licence</h2>
                <div className='licencedoc'>
                    <div className="licencedoc__head">
                        <img src={Logo} alt="Logo of company" className='licencedoc__head__logo' />
                        <img src={Regulate} alt="Logo of regulation body" className='licencedoc__head__regulator' />
                    </div>

                    <h3>{user.number.substr(0, 2) === "PR" ? 'PROVISIONAL LICENCE' : 'LICENCE'}</h3>

                    <div className="licencedoc__details">

                        <div className="licencedoc__details__body">

                            <div className="licencedoc__details__body__item">
                                <p className='licencedoc__details__body__item__label'>Full Name</p>
                                <p className='licencedoc__details__body__item__value'>{user.fullName}</p>
                            </div>

                            <div className="licencedoc__details__body__item">
                                <p className='licencedoc__details__body__item__label'>Licence No</p>
                                <p className='licencedoc__details__body__item__value'>{user.number}</p>
                            </div>

                            <div className="licencedoc__details__body__item">
                                <p className='licencedoc__details__body__item__label'>Category</p>
                                <p className='licencedoc__details__body__item__value'>{user.category}</p>
                            </div>

                            <div className="licencedoc__details__body__item licencedoc__details__body__item__office">
                                <p className='licencedoc__details__body__item__label '>Office Information</p>
                                <div className='licencedoc__details__body__item__value'>
                                    <p>{user.companyAddress}</p>
                                    <p>{user.companyTelephone}</p>
                                    <p>{user.companyEmail}</p>
                                </div>
                            </div>

                            {
                                user.number.substr(0, 2) !== "PR" &&
                                <>
                                    <div className="licencedoc__details__body__item">
                                        <p className='licencedoc__details__body__item__label'>Company Name</p>
                                        <p className='licencedoc__details__body__item__value'>{user.companyName}</p>
                                    </div>

                                    <div className="licencedoc__details__body__item">
                                        <p className='licencedoc__details__body__item__label'>Company License No</p>
                                        <p className='licencedoc__details__body__item__value'>{user.companyNumber}</p>
                                    </div>

                                    <div className="licencedoc__details__body__item">
                                        <p className='licencedoc__details__body__item__label'>Regions(s) of operation</p>
                                        <p className='licencedoc__details__body__item__value'>{user.regions}</p>
                                    </div>
                                </>
                            }

                        </div>

                        <div className="licencedoc__details__img">
                            <img src={user.passportImage} alt="Passport Img of Licencee" />
                        </div>
                    </div>
                    {
                        user.number.substr(0, 2) !== "PR" &&
                        <div className="licencedoc__regions">
                            <div className="licencedoc__regions__body">
                                <div className="licencedoc__regions__section">
                                    <p>A - Ahafo Region</p>
                                    <p>B - Ashanti Region</p>
                                    <p>C - Bono East Region</p>
                                    <p>D - Bono Region</p>
                                </div>
                                <div className="licencedoc__regions__section">
                                    <p>E - Central Region</p>
                                    <p>F - Eastern Region</p>
                                    <p>G - Greater Accra Region</p>
                                    <p>H - North East Region</p>
                                </div>
                                <div className="licencedoc__regions__section">
                                    <p>I - Northern Region</p>
                                    <p>J - Oti Region</p>
                                    <p>K - Savannah Region</p>
                                    <p>L - Upper East Region</p>
                                </div>
                                <div className="licencedoc__regions__section">
                                    <p>M - Upper West Region</p>
                                    <p>N - Volta Region</p>
                                    <p>O - Western North Region</p>
                                    <p>P - Western Region</p>
                                </div>
                            </div>

                        </div>

                    }

                    <div className="licencedoc__status">
                        <div
                            className="licencedoc__status__user"
                            style={user.isSuspended ? inlineStyle : {}}
                        >
                            {`Licencee ${user.isSuspended ? 'is Suspended' : 'in good standing'}`}
                        </div>
                        <div className="licencedoc__status__date">
                            {`Expires on ${formatDate(user.expiryDate)}`}
                        </div>
                    </div>
                </div>
                <div className="licencedoc__footer">
                    <Footer />
                </div>

            </div>
    )
}

export default LicenceDoc