import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Login } from "./features/Login";
import { CreateAccount } from "./features/Signup";
import { LicenceDoc } from "./features/LicenceDoc";
import {
  Dashboard,
  AddDocs,
  Messages,
  ResetPword,
  Licence,
  Forms,
} from "./features/Home";
import ProtectedRoute from "./ProtectedRoutes";

function Navigator() {
  return (
    <Router>
      <Routes>
        <Route
          path="/forms"
          element={
            <ProtectedRoute>
              <Forms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/licence"
          element={
            <ProtectedRoute>
              <Licence />
            </ProtectedRoute>
          }
        />
        <Route
          path="/resetpword"
          element={
            <ProtectedRoute>
              <ResetPword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messages"
          element={
            <ProtectedRoute>
              <Messages />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <ProtectedRoute>
              <AddDocs />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/licence-doc/:id" element={<LicenceDoc />} />
        <Route path="/signup" element={<CreateAccount />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default Navigator;
