import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import Rodal from 'rodal'

function Requirements(props) {

    const [inputVal, setInputVal] = useState("")
    // console.log(inputVal)
    return (

        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.8 : 500}
            height={isMobile ? 350 : 500}
            closeMaskOnClick={false}
            closeOnEsc={false}
        >
            <div className='require'>
                <h3>NOTICE</h3>

                <p>
                    Welcome to the Real Estate Agency Council Hub (REACH)!
                    Before you proceed with the registration process, please take a moment to review the attached information 
                    carefully to ensure you have an appreciation of the registration process and a smooth experience using the
                    Real Estate Agency Council Hub.
                </p>


                <button
                    className="btn require__btn"
                    onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/reachgh-d33f1.appspot.com/o/Application%20Notice%20Updated.pdf?alt=media&token=41300ffa-cd53-423f-9608-c5ef5c41e00c')}
                >
                    Read Document
                </button>
            </div>
        </Rodal>
    )
}

export default Requirements