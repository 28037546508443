import React, { createContext, useState } from 'react'

const AppDataContext = createContext();

const AppDataProvider = ({children}) => {
    const [userData, setUserData] = useState({})
    const [loggedIn, setLoggedIn] = useState(false)
    const [errorInput, setErrorInput] = useState('')

    return (
        <AppDataContext.Provider value={{ userData, setUserData, loggedIn, setLoggedIn, errorInput, setErrorInput }}>
            {children}
        </AppDataContext.Provider>
    )
}

export {AppDataContext, AppDataProvider};