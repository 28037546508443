import React from 'react'

function Declaration(props) {
    return (
        <form action="#" className="create__right__form__element" id="create__form">

            <div class="create__right__form__group__checkbox">

                <input
                    type="checkbox"
                    id="check-1"
                    className="create__right__form__input__checkbox"
                    checked={props.checkTrueInfo}
                    onChange={() => props.setCheckTrueInfo(!props.checkTrueInfo)}
                />

                <label
                    for="check-1"
                    className="create__right__form__label__checkbox"
                >
                    I hereby declare that, all information provided in this application now and hereafter is true,
                    accurate and complete to the best of my knowledge.
                    I understand that if this statement is found to be untrue or misleading,
                    the Council reserves the right to reject the application or suspend or revoke any
                    Licence that follows from the application or take the necessary legal action.
                </label>

            </div>

        </form>
    )
}

export default Declaration