import React, { useState } from 'react'
import Rodal from 'rodal'
import { useNavigate } from 'react-router-dom';
import { MdOutlineSmartphone } from "react-icons/md";
import { FcSmartphoneTablet } from "react-icons/fc";
import { HiCreditCard } from "react-icons/hi";
import { FaPhone } from "react-icons/fa";
import { isMobile } from 'react-device-detect';
import { Spinner } from '../shared'
import { createInvoice, payWithMomo, checkPaymentStatus } from './dbFunctions';
import { doc, updateDoc, getFirestore } from "firebase/firestore";

let invoiceNo;

function PaymentPage(props) {

    const [showSpinner, setShowSpinner] = useState(false)
    const [promptSent, setPromptSent] = useState(false)
    const [phoneNo, setPhoneNo] = useState('')
    const [network, setNetwork] = useState("")


    //console.log(props.userID)

    function getInvoice() {
        let amount = 0;
        let name = "Annual Registration - Agent";
        let service_code = "AR002";

        if (props.category === 'Real Estate Broker') {
            amount = 2850
            name = "Annual Registration - Broker"
            service_code = "AR001"
        } else {
            amount = 1350
            name = "Annual Registration - Agent"
            service_code = "AR002"

        }

        if (props.qualification === "I am a non-resident practitioner") {
            amount += 1000;

            if (props.category === 'Real Estate Broker') {

                name = "Foreign Qualified -Broker"
                service_code = "AR003"
            } else {

                name = "Foreign Qualified- Agent"
                service_code = "AR004"
            }
        }
        return { amount: amount + '.00', name, service_code };
    }

    function formatPhoneNumber(phoneNumber) {
        // Remove leading zeros
        phoneNumber = phoneNumber.replace(/^0+/, '');

        // Add the country code (233) if the length is less than 10
        if (phoneNumber.length < 10) {
            phoneNumber = '233' + phoneNumber;
        }

        return phoneNumber;
    }

    async function handlePayment() {
        setShowSpinner(true)
        const validNumber = checkPhoneNo()

        if (validNumber) {
            const id = generateUniqueId()
            const invoice = getInvoice()
            // CREATE INVOICE
            const res = await fetch(`/.netlify/functions/createinvoice?phonenumber=${phoneNo}&amount=${invoice.amount}&name=${invoice.name}&service_code=${invoice.service_code}&uniqueid=${id}&email=${props.email}`)
            res.json().then(async (response) => {
                console.log(response)

                if (response.message === "Success") {
                    invoiceNo = response.invoice_number
                    console.log(invoiceNo)
                    const phone = formatPhoneNumber(phoneNo)
                    //Pay with MOMO
                    const res1 = await fetch(`/.netlify/functions/paymomo?invoiceno=${invoiceNo}&mobileno=${phone}&network=${network}&amount=${invoice.amount}`)
                    res1.json().then(async response1 => {
                        console.log(response1)
                        if (response1.message === "Success") {

                            // SAVE INVOICE NUMBER

                            const db = getFirestore()

                            const userRef = doc(db, "users", props.userID);

                            try {
                                await updateDoc(userRef, {
                                    paymentInvoice: invoiceNo,
                                });
                            } catch (error) {
                                //console.log(error)
                                setShowSpinner(false)
                                alert("Cannot save payment")
                                return
                            }

                            setShowSpinner(false)
                            setPromptSent(true)
                        } else {
                            setShowSpinner(false)
                            alert('Try again')
                        }
                    })
                } else {
                    setShowSpinner(false)
                }
            })


        } else {
            setShowSpinner(false)
        }

    }

    async function handleCardPayment() {
        setShowSpinner(true)
        const phoneNumber = "0551399111"

        const id = generateUniqueId()
        const invoice = getInvoice()
        // CREATE INVOICE
        const res = await fetch(`/.netlify/functions/createinvoice?phonenumber=${phoneNo}&amount=${invoice.amount}&name=${invoice.name}&service_code=${invoice.service_code}&uniqueid=${id}&email=${props.email}`)
        res.json().then(async (response) => {
            //console.log(response)

            if (response.message === "Success") {
                invoiceNo = response.invoice_number
                //console.log(invoiceNo)


                const db = getFirestore()

                const userRef = doc(db, "users", props.userID);

                try {
                    await updateDoc(userRef, {
                        paymentInvoice: invoiceNo,
                    });
                } catch (error) {
                    //console.log(error)
                    setShowSpinner(false)
                    alert("Cannot save payment")
                    return
                }

                setShowSpinner(false)
                setPromptSent(true)

                window.open(response.checkout_url)
            } else {
                setShowSpinner(false)
                alert('Try again')
            }
        })
    }

    function checkPhoneNo() {
        if (phoneNo.length === 10) {
            if (network === "") {
                alert('Select Network')
            } else {
                //console.log(network)
                return true
            }
        } else {
            alert("Phone number must be 10 digits")
            return false
        }
    }

    async function checkPaymentStatus() {
        //QUERY INVOICE
        // INVOICE GET LOST AT THIS POINT
        // const invoiceNo = '240918052533976'
        //console.log(invoiceNo)
        const res = await fetch(`/.netlify/functions/queryinvoice?invoiceno=${invoiceNo}`)
        res.json().then(response => {
            //console.log(response)

            if (response.invoice_status === "PAID") {
                alert("Payment Successful")
                props.hideModal()
            } else {
                alert("Could not confirm payment. Contact admin")
            }
        })
    }

    function generateUniqueId() {
        const timestamp = Date.now();
        const randomPart = Math.floor(Math.random() * 10000);
        const uniqueId = timestamp + randomPart;
        return uniqueId;
    }

    return (
        <Rodal
            visible={props.showModal}
            onClose={() => props.hideModal()}
            width={isMobile ? window.innerWidth * 0.88 : 600}
            height={isMobile ? 550 : 600}
            closeMaskOnClick={false}
            showCloseButton={false}
        >
            <div className='payment'>
                <div className="payment__label">
                    <h3>PAY WITH</h3>
                    <div className="payment__label__item">
                        <MdOutlineSmartphone className='payment__label__item__icon' />
                        <p>Mobile Money</p>
                    </div>
                    <div
                        className="payment__label__item"
                        onClick={() => handleCardPayment()}
                    >
                        <HiCreditCard className='payment__label__item__icon' />
                        <p>Card</p>
                    </div>
                </div>
                <div className="payment__main">
                    <div className="payment__main__head">
                        <FcSmartphoneTablet className="payment__main__head__icon" />
                        <p>info@reac.gov.gh</p>
                    </div>
                    <h2>{`Pay Ghc ${getInvoice().amount}`}</h2>
                    <div className="payment__main__body">
                        {
                            showSpinner ?
                                <Spinner
                                    showSpinner={showSpinner}
                                    height={300}
                                    small={true}
                                    width={isMobile ? window.innerWidth * 0.55 : 400}
                                /> :
                                promptSent ?
                                    <Confirm
                                        checkStatus={checkPaymentStatus}
                                        setPromptSent={setPromptSent}
                                    /> :
                                    <Initiate
                                        phoneNo={phoneNo}
                                        setPhoneNo={setPhoneNo}
                                        network={network}
                                        setNetwork={setNetwork}
                                        setPromptSent={setPromptSent}
                                        handleSubmit={handlePayment}
                                    />
                        }


                    </div>
                    <div className="payment__main__note">
                        Card Payments would redirect you to another website to include all other payment options
                    </div>
                </div>


            </div>
        </Rodal>
    )
}

export default PaymentPage

const Confirm = (props) => {

    return (
        <div className='payment__main__confirm'>
            <div className='payment__main__confirm__text'>
                <p>Please check your approvals and accept the payment on your Phone</p>
                <p>Once you are done with the payment, click on done payment to verify</p>
                <p>If incase you did not receive any transaction in your approval, try again.</p>

            </div>
            <div className="payment__main__confirm__btnsection">
                <button
                    className='btn'
                    onClick={() => props.checkStatus()}
                >
                    Done Payment
                </button>
                <button
                    className='btn'
                    onClick={() => props.setPromptSent(false)}
                >
                    Retry
                </button>
            </div>
        </div>
    )
}

const Initiate = (props) => {
    return (
        <>
            <div className="payment__main__inputbox">
                <input
                    type="text"
                    className='payment__main__input'
                    placeholder='Phone Number'
                    value={props.phoneNo}
                    onChange={(e) => props.setPhoneNo(e.target.value)}
                />
                <FaPhone className="payment__main__inputbox__icon" />
            </div>

            <div className="payment__main__inputbox">
                <select
                    type="text"
                    className='payment__main__input payment__main__input__select'
                    value={props.network}
                    onChange={(e) => props.setNetwork(e.target.value)}
                >
                    <option hidden value="">Choose Network</option>
                    <option value="MTN_MM">MTN</option>
                    <option value="VODAFONE_CASH">Telecel</option>
                    <option value="TIGO_CASH">Airtel/Tigo</option>
                </select>
            </div>

            <div
                className="payment__main__btnsection"
                onClick={() => props.handleSubmit()}
            //onClick={() => props.setPromptSent(true)}
            >
                <button className='btn payment__main__btn'>CONFIRM</button>
            </div>
        </>
    )
}