import { collection, getFirestore, doc, getDoc } from "firebase/firestore";
import _ from "lodash";

async function getLicence(id) {

    const db = getFirestore()

  const docRef = doc(db, "license", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    // console.log("Document data:", docSnap.data());
    return docSnap.data()
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
}

export {getLicence}
