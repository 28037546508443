import { doc, updateDoc, getFirestore } from "firebase/firestore";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";
// import _ from 'lodash'

async function uploadUserDocs(
  data,
  setUserData,
  docs,
  setSpinner,
  setInput,
  settSuccess,
  setError
) {
  setSpinner(true);

  // console.log(docs)

  uploadMultipleFiles(docs).then(async (downloadURLs) => {
    let urls = [];
    const db = getFirestore();

    const userRef = doc(db, "users", data.id);

    if (data.hasOwnProperty("addedDocs")) {
      urls = [...data.addedDocs, ...downloadURLs];
    } else {
      urls = downloadURLs;
    }

    try {
      await updateDoc(userRef, {
        addedDocs: urls,
      });
    } catch (error) {
      // console.log(error)
      setSpinner(false);
      setError("Error uploading file");
      return;
    }

    // console.log("Upload success")

    const editedObj = data;

    editedObj.addedDocs = urls;
    setUserData(editedObj);

    setInput([]);
    setSpinner(false);
    settSuccess("Files uploaded Successfully");
  });
}

// Helper function to upload a document to Firebase Storage
async function uploadFileAndGetURL(file, fileName) {
  const storage = getStorage();

  // Create a unique file name by appending the current timestamp
  const uniqueFileName = `${Date.now()}_${fileName}`;
  const storageRef = ref(storage, uniqueFileName);

  try {
    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);
    // console.log("File uploaded successfully");

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    // console.log("Download URL:", downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    // throw error;
  }
}

async function uploadMultipleFiles(files) {
  // console.log(files);
  const downloadURLs = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    const fileName = file.name; // Use the original file name

    try {
      // Upload the file and get the download URL
      const downloadURL = await uploadFileAndGetURL(file, fileName);
      // Push the download URL into the array
      downloadURLs.push(downloadURL);
    } catch (error) {
      console.error(`Error uploading file ${fileName}:`, error);
    }
  }

  return downloadURLs;
}
async function updatePassword(
  user,
  newPassword,
  setSuccess,
  setError,
  setUser,
  setShowSpinner,
  navigate
) {
  const db = getFirestore();
  try {
    const userRef = doc(db, "users", user.id);
    await updateDoc(userRef, {
      password: newPassword,
    });

    setUser({ ...user, password: newPassword });
    setShowSpinner(false);
    setSuccess("Password updated successfully");
    // navigate('/')
    // console.log('Password updated successfully.');
  } catch (error) {
    setShowSpinner(false);
    setError("Error updating password");

    // console.error('Error updating password:', error);
    // throw error;
  }
}

async function addBrokerInfo(user, agent, setUser, setShowSpinner, setSuccess, setError) {
    const db = getFirestore();
  try {
    const userRef = doc(db, "users", user.id);
    await updateDoc(userRef, {
      agent
    });

    setUser({ ...user, agent });
    setShowSpinner(false);
    setSuccess("Data uploaded successfully");
    
  } catch (error) {
    setShowSpinner(false);
    setError("Error uploading data");

    
  }
}

export { uploadUserDocs, updatePassword, addBrokerInfo };
