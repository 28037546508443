import React from 'react'

import { MdCategory, MdRealEstateAgent, MdAccessTimeFilled, MdGroupWork, MdOutlineWork } from "react-icons/md";
import { PiCertificateFill, PiShieldStarFill } from "react-icons/pi";
import { BsBarChartLineFill, BsTelephoneFill, BsCalendarDateFill, BsCalendarDay, BsPersonRaisedHand } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { IoBusiness } from "react-icons/io5";
import { BiSolidBusiness } from "react-icons/bi";
import { GiHouse } from "react-icons/gi";
import { HiMiniNewspaper, HiClipboardDocumentList } from "react-icons/hi2";
import { FaFlag, FaCalendarDay } from "react-icons/fa";
import { ImOffice } from "react-icons/im";
import { COUNTRIES } from '../shared/Constants';
import { Input, Select, DateInput, CheckBoxGroup, InputArea } from '../shared/Inputs';
const YESNO = ['YES', 'NO']

const PRO_CATEGORIES = ['Real Estate Broker', 'Real Estate Agent']
const SPECIALISATION_CATEGORIES = ['Real Estate Broker', 'Real Estate Developer', 'Facility/Estate  Manager', 'Real Estate Marketer']
const QUALIFICATION = [
    'I am a valuer licenced by GhIS',
    'I am a lawyer licenced by GLC',
    'I am a real estate agency practitioner with prior experience',
    'I am a non-resident practitioner'
]
const EXPERIENCE_YEARS = ['1 year', '2 years', '3 years', '4 years', '5 years', '6 years', '7 years', '8 years', '9 years', '10 years and above']
const OPERATION = ['Company or Partnership', 'Self-employed', 'Employee of a company']

function CategoryInfo(props) {
    return (
        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>



            <Select
                id="professionalCategory"
                label="What professional category do you want to be registered under?"
                value={props.professionalCategory}
                setValue={props.setProfessionalCategory}
                placeholder="Select Professional category"
                list={PRO_CATEGORIES}
            >
                <MdCategory />
            </Select>





            {
                props.professionalCategory === "Real Estate Agent" &&
                <>
                    <Input
                        id="agentName"
                        label="Name of Broker you intend to be affiliated with (If Any)"
                        value={props.agent.agentName}
                        setValue={props.handleAgentChange}
                        placeholder="Affiliate Name"
                        type="obj"
                    >
                        <MdRealEstateAgent />
                    </Input>

                    <Input
                        id="agentLicence"
                        label="Licence number of affiliated broker (If Any)"
                        value={props.agent.agentLicence}
                        setValue={props.handleAgentChange}
                        placeholder="Affiliate Broker's Licence (If Any)"
                        type="obj"
                    >
                        <HiClipboardDocumentList />
                    </Input>

                    <Input
                        id="agentBusiness"
                        label=" Business/Company Name of Broker (If Any)"
                        value={props.agent.agentBusiness}
                        setValue={props.handleAgentChange}
                        placeholder="Affiliate Business Name (If Any)"
                        type="obj"
                    >
                        <BiSolidBusiness />
                    </Input>

                    <Input
                        id="agentEmail"
                        label="Email address of the Broker (If Any)"
                        value={props.agent.agentEmail}
                        setValue={props.handleAgentChange}
                        placeholder="Affiliate Email (If Any)"
                        type="obj"
                    >
                        <IoIosMail />
                    </Input>

                    <Input
                        id="agentAddress"
                        label="Business address of the Broker (with Ghana post GPS) (If Any)"
                        value={props.agent.agentAddress}
                        setValue={props.handleAgentChange}
                        placeholder="Affiliate Business Address (If Any)"
                        type="obj"
                    >
                        <GiHouse />
                    </Input>

                    <Input
                        id="agentPhone"
                        label="Phone Number of the Broker (If Any)"
                        value={props.agent.agentPhone}
                        setValue={props.handleAgentChange}
                        placeholder="Affiliate Phone Number (If Any)"
                        type="obj"
                    >
                        <BsTelephoneFill />
                    </Input>

                </>

            }

            <Select
                id="specialisation"
                label="What is your Specialisation?"
                value={props.specialisation}
                setValue={props.setSpecialisation}
                placeholder="Select your specialisation"
                list={SPECIALISATION_CATEGORIES}
            >
                <PiShieldStarFill />
            </Select>

            <Select
                id="qualification"
                label="How do you qualify?"
                value={props.qualification}
                setValue={props.setQualification}
                placeholder="Choose Qualification"
                list={QUALIFICATION}
            >
                <PiCertificateFill />
            </Select>

            {
                props.qualification === "I am a valuer licenced by GhIS" &&
                <>

                    <Input
                        id="GhISDiplomaNo"
                        label="Diploma Number"
                        value={props.GhISDiplomaNo}
                        setValue={props.setGhISDiplomaNo}
                        placeholder="GhIS Diploma Number"
                    >
                        <HiMiniNewspaper />
                    </Input>

                    <DateInput
                        id="GhISDateOfIssue"
                        label="Date Of Issue"
                        value={props.GhISDateOfIssue}
                        setValue={props.setGhISDateOfIssue}

                    >
                        <BsCalendarDateFill />
                    </DateInput>



                </>
            }



            {
                props.qualification === "I am a lawyer licenced by GLC" &&
                <>

                    <Input
                        id="GLCLicenceNo"
                        label="Licence/Diploma Number"
                        value={props.GLCLicenceNo}
                        setValue={props.setGLCLicenceNo}
                        placeholder="Licence/Diploma Number"
                    >
                        <HiMiniNewspaper />
                    </Input>

                    <DateInput
                        id="GLCDateOfIssue"
                        label="Date of issue"
                        value={props.GLCDateOfIssue}
                        setValue={props.setGLCDateOfIssue}

                    >
                        <BsCalendarDateFill />
                    </DateInput>



                </>
            }

            {
                props.qualification === "I am a real estate agency practitioner with prior experience" &&

                <Select
                    id="experience"
                    label="Choose Years of experience"
                    value={props.experience}
                    setValue={props.setExperience}
                    placeholder="Choose Years of experience"
                    list={EXPERIENCE_YEARS}
                >
                    <BsBarChartLineFill />
                </Select>

            }

            {
                props.qualification === "I am a non-resident practitioner" &&
                <>

                    <Select
                        id="NRPCountry"
                        label="Country Of Practice"
                        value={props.NRP.NRPCountry}
                        setValue={props.handleNRPChange}
                        placeholder="Select Country of Practice"
                        list={COUNTRIES}
                        type="obj"
                    >
                        <FaFlag />
                    </Select>

                    <Input
                        id="NRPRegulator"
                        label="Institution regulating/Issuing licence"
                        value={props.NRP.NRPRegulator}
                        setValue={props.handleNRPChange}
                        placeholder="Licence Regulated By"
                        type="obj"
                    >
                        <ImOffice />
                    </Input>


                    <Input
                        id="NRPLicenceNo"
                        label="Licence Number"
                        value={props.NRP.NRPLicenceNo}
                        setValue={props.handleNRPChange}
                        placeholder="Licence Number"
                        type="obj"
                    >
                        <HiMiniNewspaper />
                    </Input>

                    <DateInput
                        id="NRPDateOfIssue"
                        label="Date of Issue"
                        value={props.NRPDateOfIssue}
                        setValue={props.setNRPDateOfIssue}

                    >
                        <BsCalendarDateFill />
                    </DateInput>


                </>
            }

            {
                props.professionalCategory === "Real Estate Broker" &&
                <Select
                    id="operation"
                    label="How do you intend to operate?"
                    value={props.operation}
                    setValue={props.setOperation}
                    placeholder="Choose how you operate"
                    list={OPERATION}
                >
                    <IoBusiness />
                </Select>
            }

            {
                props.operation === "Company or Partnership" &&

                <Select
                    id="designatedOfficer"
                    label="Are you the Designated officer of the company?"
                    value={props.designatedOfficer}
                    setValue={props.setDesignatedOfficer}
                    placeholder="Select whether officer or not"
                    list={YESNO}
                >
                    <BsPersonRaisedHand />
                </Select>
            }


            {
                /*
                props.operation === "Employee of a company" &&

                <Select
                    id="employer"
                    label="Choose your company"
                    value={props.employer}
                    setValue={props.setEmployer}
                    placeholder="Select Company"
                    list={REALESTATEMOCKDATA}
                >
                    <MdBusinessCenter />
                </Select>
                */
            }

            <Select
                id="workTime"
                label="Full time or part  time ?"
                value={props.workTime}
                setValue={props.setWorkTime}
                placeholder="Select Work Time"
                list={["Full Time", "Part Time"]}
            >
                <MdAccessTimeFilled />
            </Select>


            {
                props.workTime === "Part Time" &&
                <>

                    <Select
                        id="partTimeNoOfDays"
                        label="How many days do you work in a week?"
                        value={props.partTime.partTimeNoOfDays}
                        setValue={props.handlePartTimeChange}
                        placeholder="Select No Of Days"
                        list={["1", "2", "3", "4", "5", "6", "7"]}
                        type="obj"
                    >
                        <FaCalendarDay />
                    </Select>

                    <CheckBoxGroup
                        id="partTimeDays"
                        label="Tick days do you work in a week?"
                        checkboxesid="partTimeDayItem"
                        setValue={props.handleCheckboxClick}
                        value={props.partTime.partTimeDays}
                        expectedNo={parseInt(props.partTime.partTimeNoOfDays)}
                        list={["Monday", "Tuesday", "WednesDay", "Thursday", "Friday", "Saturday", "Sunday"]}
                    >
                        <BsCalendarDay />
                    </CheckBoxGroup>
                </>
            }

            <Select
                id="currentEmployment"
                label="Will you be engaged or employed in any business, occupation or profession other than as a real estate agent/broker?"
                value={props.currentEmployment}
                setValue={props.setCurrentEmployment}
                placeholder="Select whether employed or not"
                list={YESNO}
            >
                <MdGroupWork />
            </Select>

            {
                props.currentEmployment === "YES" &&
                <InputArea
                    id="currentEmploymentName"
                    label="Provide Details"
                    value={props.currentEmploymentName}
                    setValue={props.setCurrentEmploymentName}
                    placeholder={`Valuer at ABC Valuation Services, 
Accountant at XYZ Accounting firm`}
                >
                    <MdOutlineWork />
                </InputArea>

            }


        </form>
    )
}

export default CategoryInfo