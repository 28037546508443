import React, { useRef, useState, useContext } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import File from '../../img/file.png'
import Nofile from '../../img/no-file.png';
import Logo from '../../img/Logo-nobg.png'
import { uploadUserDocs } from './dbFunctions';
import { Spinner, Footer } from '../shared';
import _ from 'lodash'
import Header from './Header';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from './Sidebar';
import HeadImg from '../../img/upload-file.png'


function AddDocs(props) {

    const { userData, setUserData } = useContext(AppDataContext)
    const [userFiles, setUserFiles] = useState([])
    const [showSpinner, setShowSpinner] = useState(false)
    const fileRef = useRef()

    const setSuccess = (message) => toast.success(message, { position: "top-center" });
    const setError = (message) => toast.error(message, { position: "top-center" });

    function handleFileChange(e) {
        // console.log(userFiles)
        setUserFiles(e.target.files)
    }

    function handleSubmit() {
        if (userFiles) {
            setShowSpinner(true)

            // props.user and appdata should be merged since in the clien website, after login we only fetch the users login details
            // uploadUserDocs(props.user, userFiles, setShowSpinner, userData, setUserData, setUserFiles, setSuccess, setError)
            uploadUserDocs(userData, setUserData, userFiles, setShowSpinner, setUserFiles, setSuccess, setError)
        } else {
            alert("Upload a file first")
        }
    }

    const inlineStyle = {
        display: 'none',
        width: '0'
    }

    return (
        <div className="home">
            <Header />
            <Sidebar />
            <div className="home__main">
                <div className="home__main__header">
                    <p>Your Document Submission Section</p>
                    <h3>SUBMIT DOCUMENTS</h3>

                </div>

                <div className="home__main__content">
                    <div className='adddocs'>

                        <div className="adddocs__container">

                            <div className="adddocs__header">
                                <div className="adddocs__header__text">
                                    <h4>Welcome to your Dashboard Area</h4>
                                    <p>Real Estate Agency Council Hub Ghana</p>
                                </div>

                                <div className="adddocs__header__img">
                                    <img src={HeadImg} alt="Page Icon" />
                                </div>

                            </div>

                            <Spinner
                                showSpinner={showSpinner}
                                height="600px"
                                size="small"
                                width="800px"
                            />
                            <div className="adddocs__content"  style={showSpinner ? inlineStyle : {}}>

                                <div className="adddocs__content__container">
                                    <p>
                                        Please click on the file to upload single or multiple documents
                                        requested from admins.
                                    </p>

                                    <h4>Upload Docs (Any Type)</h4>

                                    <div className="adddocs__content__upload">
                                        <div className="adddocs__content__upload__preview">
                                            <input
                                                type="file"
                                                hidden
                                                multiple
                                                style={{ width: 0, display: 'none' }}
                                                id="userfiles"
                                                required
                                                onChange={handleFileChange}
                                                ref={fileRef}
                                            />
                                            <label htmlFor="userfiles">
                                                <div className="adddocs__content__upload__preview__imgbox">
                                                    <img src={_.isEmpty(userFiles) ? Nofile : File} alt="Preview of uploaded file" />
                                                </div>
                                                <p>{userFiles ? `${userFiles.length} Document(s) Selected` : 'Click to upload documents'}</p>
                                            </label>
                                        </div>
                                    </div>




                                    <div className="adddocs__btnsection">
                                        <button
                                            className="btn adddocs__btn"
                                            onClick={() => handleSubmit()}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                                <div className="adddocs__content__note">
                                    <p>
                                        Please Upload documents or images only required by the admins in the message section.
                                        Any uploaded document cannot be deleted from the system.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    )
}

export default AddDocs