import React from 'react'
import Logo from '../../img/Logo-nobg.png'
import { MdEmail, MdLocationPin, MdPhone } from 'react-icons/md'


function Footer(props) {

  return (
    <div className='footer'>
      <div className="footer__img">
        <img src={Logo} alt="Logo of Company" />
      </div>
      <div className="footer__list">
        <ul>
          <li>
            <h4><MdPhone/> Office Number</h4>
            <p>+233 (0)302 773 339</p>
          </li>
          <li>
            <h4><MdEmail/> Email</h4>
            <p>info@reac.gov.gh</p>
          </li>
          <li>
            <h4><MdLocationPin/> Digital Address</h4>
            <p>GL 042 5760</p>
          </li>
          
        </ul>
      </div>
      <div className="footer__madeby">
        &copy; Real Estate Agency Council  - All Rights Reserved. 2024
      </div>
    </div>
  )
}

export default Footer