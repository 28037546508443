import React from 'react'

import { MdPhoneAndroid, MdSimCard } from "react-icons/md";
import { BsFlagFill, BsFillTelephoneFill, BsTelephoneFill } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { FaHouseUser, FaCity } from "react-icons/fa";
import { GiPostOffice } from "react-icons/gi";
import { RiUserLocationFill } from "react-icons/ri";
import { Input, Select } from '../shared/Inputs';
import { REGIONS_DISTRICT } from '../shared/Constants';
const YESNO = ['YES', 'NO']

function ContactInfo(props) {

    return (

        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>

        
            <Input
                id="residentialAddress"
                label="Residential Address"
                value={props.residentialAddress}
                setValue={props.setResidentialAddress}
                placeholder="eg. No. 12 Oxford Street, Osu"
            >
                <FaHouseUser />
            </Input>

            <Input
                id="ghanaPostAddress"
                label="Ghana Post Address"
                value={props.ghanaPostAddress}
                setValue={props.setGhanaPostAddress}
                placeholder="eg. GW-042-6057"
            >
                <RiUserLocationFill />
            </Input>

            

            <Select
                id="region"
                label="Region"
                value={props.region}
                setValue={props.setRegion}
                placeholder="Select Region"
                list={Object.keys(REGIONS_DISTRICT)}
            >
                <BsFlagFill />
            </Select>


            <Select
                id="district"
                label="District"
                value={props.district}
                setValue={props.setDistrict}
                placeholder="District"
                list={props.region ? REGIONS_DISTRICT[props.region] : []}
            >
                <FaCity />
            </Select>

            <Input
                id="postalAddress"
                label=" Postal Address"
                value={props.postalAddress}
                setValue={props.setPostalAddress}
                placeholder="eg. P.O. Box GP 1234 Accra"
            >
                 <GiPostOffice />
            </Input>

            <Input
                id="email"
                label="Email Address"
                value={props.email}
                setValue={props.setEmail}
                placeholder="eg. Johnsondwams@gmail.com"
            >
                 <IoIosMail />
            </Input>

            <Input
                id="landlineNo"
                label="Landline Number (If Any)"
                value={props.landlineNo}
                setValue={props.setLandlineNo}
                placeholder="Enter Landline Number"
                checktype="number"
            >
                 <BsFillTelephoneFill />
            </Input>

            <Input
                id="mobileNo"
                label={`Ghana Mobile Number - This Number would be used for payment in later options so please crosscheck`}
                value={props.mobileNo}
                setValue={props.setMobileNo}
                placeholder="eg. 0550100100"
                checktype="number"
            >
                 <BsTelephoneFill />
            </Input>

            <Select
                id="mobileLinkedToCard"
                label="Is mobile linked to your Ghana Card ?"
                value={props.mobileLinkedToCard}
                setValue={props.setMobileLinkedToCard}
                placeholder="Is number connected to card"
                list={YESNO}
            >
                <BsFlagFill />
            </Select>

        </form>
    )
}

export default ContactInfo