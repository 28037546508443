import React, { useContext } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from './Sidebar';
import Header from './Header';
import HeadImg from '../../img//chat-box.png'
import Logo from '../../img/Logo-nobg.png'
import { FaUserAstronaut } from "react-icons/fa";
import User from '../../img/Avatar.png'
import { Footer } from '../shared';
import NoMsg from '../../img/no-msg.png';

function Messages() {

    const { userData } = useContext(AppDataContext)
    console.log(userData)

    return (
        <div className="home">
            <Header />
            <Sidebar />
            <div className="home__main">
                <div className="home__main__header">
                    <p>Personal Chats & Messages Area</p>
                    <h3>Messages</h3>

                </div>

                <div className="home__main__content">
                    <div className='messages'>
                        <div className="messages__container">

                            <div className="messages__header">
                                <div className="messages__header__text">
                                    <h4>Welcome to your Message Box</h4>
                                    <p>Real Estate Agency Council Hub Ghana</p>
                                </div>

                                <div className="messages__header__img">
                                    <img src={HeadImg} alt="Page Icon" />
                                </div>

                            </div>

                            <div className="messages__content">

                                <div className="messages__content__container">

                                    {
                                        userData.messages ?
                                        userData.messages.map((item, index) => <MessageItem data={item}/>):
                                        <div className="licence__content__nodoc">
                                            <img src={NoMsg} alt="No Licence" />
                                            <p>No Messages</p>
                                        </div>
                                    }

        
                                </div>

                                <div className="messages__content__note">
                                    <p>
                                        Please these are private read-only messages sent from the admins to you.
                                        In order to reply, send it via any of our email services
                                    </p>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    )
}

export default Messages


const MessageItem = (props) => {
    return (
        <div className="messages__item">
            <div className="messages__item__avatar">
                <img src={User} alt="Avatar of Creator of messages" />
            </div>
            <div className="messages__item__content">
                <div className="messages__item__content__head">
                    <FaUserAstronaut className="messages__item__content__head__icon" />
                    <span>Admin</span>
                    <p>{(props.data.time.substr(0, 16))}</p>
                </div>
                <div className="messages__item__content__text">
                    {props.data.message}
                </div>
            </div>
        </div>
    )
}