import React from 'react'
import { RiUser2Fill, RiUser5Fill, RiUserSmileFill } from "react-icons/ri";
import { MdOutlinePersonPinCircle } from "react-icons/md";
import { PiGenderIntersexDuotone, PiFlagCheckeredBold, PiFlagBannerFill } from "react-icons/pi";
import { BsCalendarDateFill } from "react-icons/bs";
import { IoIosPerson } from "react-icons/io";
import { FaRegIdCard } from "react-icons/fa";
import { HiDocument } from "react-icons/hi2";
import { COUNTRIES } from '../shared/Constants';
import { Input, Select, DateInput, LetterInput } from '../shared/Inputs';


const TITLE_LIST = ['MR', 'MISS', 'MRS', 'SIR', 'DR', 'PROF', 'OTHER']
const GENDER_LIST = ['Male', 'Female']
const YESNO = ['YES', 'NO']

const inlineStyle = {
    display: 'none',
    width: '0'
}

function PersonalInfo(props) {
    return (

        <form action="#" className="create__right__form__element" id="create__form">

            <Select
                id="title"
                label="Title"
                value={props.title}
                setValue={props.setTitle}
                placeholder="Choose Title"
                list={TITLE_LIST}
            >
                <MdOutlinePersonPinCircle />
            </Select>

            {
                props.title === 'OTHER' &&

                <LetterInput
                    id="otherTitle"
                    label="Other Title"
                    value={props.otherTitle}
                    setValue={props.setOtherTitle}
                    placeholder="Enter Your Title"
                    isLettersOnly
                >
                    <RiUserSmileFill />
                </LetterInput>
            }

            <LetterInput
                id="firstName"
                label="First Name"
                value={props.firstName}
                setValue={props.setFirstName}
                placeholder="eg. Johnson"
                isLettersOnly
            >
                <IoIosPerson />
            </LetterInput>

            <LetterInput
                id="middleName"
                label="Middle Name (Optional)"
                value={props.middleName}
                setValue={props.setMiddleName}
                placeholder="Enter Middle name if any"
                isLettersOnly
            >
                <RiUser2Fill />
            </LetterInput>

            <LetterInput
                id="lastName"
                label="Last Name"
                value={props.lastName}
                setValue={props.setLastName}
                placeholder="eg. Dwamena"
                isLettersOnly
            >
                <RiUser5Fill />
            </LetterInput>


            <Select
                id="gender"
                label="Gender"
                value={props.gender}
                setValue={props.setGender}
                placeholder="Choose Gender"
                list={GENDER_LIST}
            >
                <PiGenderIntersexDuotone />
            </Select>

            <DateInput
                id="dob"
                label="Date Of Birth"
                value={props.dob}
                setValue={props.setDob}

            >
                <BsCalendarDateFill />
            </DateInput>

            <Input
                id="ghanaCardNo"
                label="Ghana Card Number"
                value={props.ghanaCardNo}
                setValue={props.setGhanaCardNo}
                placeholder="Format (GHA-XXXXXXXXX-X)"
            >
                <FaRegIdCard />
            </Input>

            <Select
                id="nationalities"
                label="Country Of Citizenship"
                value={props.nationalities}
                setValue={props.setNationalities}
                placeholder="Select Country Of Citizenship"
                list={COUNTRIES}
            >
                <PiFlagCheckeredBold />
            </Select>

            <Select
                id="residentCountry"
                label=" Country of Residence"
                value={props.residentCountry}
                setValue={props.setResidentCountry}
                placeholder="Select Country of Residence"
                list={COUNTRIES}
            >
                <PiFlagBannerFill />
            </Select>

            {
                props.nationalities !== "Ghana" &&
                (
                    <>
                        <Select
                            id="residencePermit"
                            label="Do you have Residence Work Permit?"
                            value={props.residencePermit}
                            setValue={props.setResidencePermit}
                            placeholder="Do you have Residence Work Permit?"
                            list={YESNO}
                        >
                            <HiDocument />
                        </Select>


                        <Input
                            id="residencePermitNo"
                            label="Residence/Work Permit (If Any)"
                            value={props.residencePermitNo}
                            setValue={props.setResidencePermitNo}
                            placeholder="Residence/Work Permit Number"
                        >
                            <FaRegIdCard />
                        </Input>
                    </>
                )
            }


        </form>
    )
}

export default PersonalInfo