import React from 'react'

import { BsFillTelephoneFill, BsFillPinMapFill } from "react-icons/bs";
import { IoIosBusiness, IoIosMail } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa";
import { GiPostOffice } from "react-icons/gi";
import { Input, InputArea } from '../shared/Inputs';

function SoleInfo(props) {
    return (
        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>

            <Input
                id="companyAddress"
                label="Physical address of your office with GPS"
                value={props.companyAddress}
                setValue={props.setCompanyAddress}
                placeholder="Physical address of your office with GPS"
            >
                <FaLocationArrow />
            </Input>

            <Input
                id="companyPostalAddress"
                label="Postal address of your office"
                value={props.companyPostalAddress}
                setValue={props.setCompanyPostalAddress}
                placeholder="Enter Postal address of your office"
            >
                <GiPostOffice />
            </Input>

            <Input
                id="companyEmail"
                label="Office Email address"
                value={props.companyEmail}
                setValue={props.setCompanyEmail}
                placeholder="Enter Email address of your office"
            >
                <IoIosMail />
            </Input>

            <Input
                id="companyTelephone"
                label="Office telephone number"
                value={props.companyTelephone}
                setValue={props.setCompanyTelephone}
                placeholder="Enter office telephone number"
                checktype="number"
            >
                <BsFillTelephoneFill />
            </Input>

            {
                props.professionalCategory === "Real Estate Broker" &&
                <InputArea
                    id="companyRegions"
                    label="Regions of operation (Address and Contact)"
                    value={props.companyRegions}
                    setValue={props.setCompanyRegions}
                    placeholder="Regions of operation (Address and Contact)

                    Provide the address of the office(s) in the selected region(s)*
                    Provide the email address of each office*
                    Provide the telephone number(s) of each office*
                    Designated officers of the branch office(s)
                    "
                >
                    <BsFillPinMapFill />
                </InputArea>
            }

        </form>
    )
}

export default SoleInfo