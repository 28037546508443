import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import Logo from '../../img/Logo-nobg.png'
import { ImMenu } from "react-icons/im";
import { RiDashboardFill, RiLockPasswordFill } from "react-icons/ri";
import { TbLicense } from "react-icons/tb";
import { AiFillMessage } from "react-icons/ai";
import { IoDocuments, IoLogOutSharp } from "react-icons/io5";
import { AppDataContext } from '../../Contexts/AppDataContext';
import { SiGoogleforms } from 'react-icons/si';

const inlineStyle = {
    display: 'flex',
    width: '100%'
}

function Header(props) {

    const {userData} = useContext(AppDataContext)
    const [showDD, setShowDD] = useState(false)
    const navigate = useNavigate()

    function navigateTo(path) {
        setShowDD(false)
        navigate(path)
    }

    function showHelp() {
        setShowDD(false)
        props.setShowHelp(true)
    }

    return (
        <div className='header'>
            <div className="header__main">
                <div className="header__main__img">
                    <img src={Logo} alt="Logo of Company" />
                </div>

                <div className="header__main__menu">
                    <img src={userData.passportLink} alt="Avatar of user" className='header__user__icon' />
                    <ImMenu onClick={() => setShowDD(!showDD)}/>
                </div>
            </div>
            <div
                className="header__dropdown"
                style={showDD ? inlineStyle : {}}
            >
                <ul>
                    <li
                        onClick={() => navigateTo('/dashboard')}
                    >
                        <RiDashboardFill />
                        <span>Dashboard</span>
                    </li>
                    <li
                        onClick={() => navigateTo('/licence')}
                    >
                        <TbLicense />
                        <span>Licence</span>
                    </li>
                    <li
                        onClick={() => navigateTo('/messages')}
                    >
                        <AiFillMessage />
                        <span>Messages</span>
                    </li>
                    <li
                        onClick={() => navigateTo('/upload')}
                    >
                        <IoDocuments />
                        <span>Upload Documents</span>
                    </li>
                    <li onClick={() => navigateTo('/forms')}>
                        <SiGoogleforms />
                        <span>Forms</span>
                    </li>
                    <li onClick={() => navigateTo('/resetpword')}>
                        <RiLockPasswordFill />
                        <span>Reset Password</span>
                    </li>
                    
                    <li onClick={() => navigateTo('/')}>
                        <IoLogOutSharp />
                        <span>Logout</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Header