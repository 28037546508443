import React, { useEffect, useState, useRef, useContext } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import { Spinner, Footer } from '../shared'
import { GiClover } from "react-icons/gi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify'
import _ from 'lodash'
import Avatar from '../../img/Avatar.png'
import DocPreview from '../../img/png-file.png'
import Cardfront from '../../img/cardfront.png'
import Cardback from '../../img/cardback.png'
import Logo from '../../img/Logo-nobg.png'
import { IoMdArrowDropdownCircle } from "react-icons/io";
import PersonalInfo from './PersonalInfo';
import ContactInfo from './ContactInfo';
import CompanyInfo from './CompanyInfo';
import CategoryInfo from './CategoryInfo';
import SoleInfo from './SoleInfo';
import CriminalHistory from './CriminalHistory';
import Declaration from './Declaration';
import { createUser, checkEmailExists } from './dbFunctions';
import PaymentPage from './PaymentPage';
import dayjs from 'dayjs';
import Requirements from './Requirements';
import Template from './Template';

const inlineStyle = {
    display: 'none',
    width: '0',
}

function CreateAccount() {

    const { errorInput, setErrorInput } = useContext(AppDataContext)

    const [btnHeight, setBtnHeight] = useState(0)
    const [pageNo, setPageNo] = useState(0)
    const [showSpinner, setShowSpinner] = useState(false)
    const [showPayment, setShowPayment] = useState(false)
    const [userID, setUserID] = useState('')
    const [showRequirements, setShowRequirements] = useState(true)

    const [title, setTitle] = useState('')
    const [otherTitle, setOtherTitle] = useState('')
    const [gender, setGender] = useState('')
    const [dob, setDob] = useState('')
    const [firstName, setFirstName] = useState('')
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [ghanaCardNo, setGhanaCardNo] = useState('')
    const [residentCountry, setResidentCountry] = useState('')
    const [nationalities, setNationalities] = useState('')
    const [residencePermit, setResidencePermit] = useState('')
    const [residencePermitNo, setResidencePermitNo] = useState('')

    const [checkTrueInfo, setCheckTrueInfo] = useState(false)

    const [residentialAddress, setResidentialAddress] = useState('')
    const [ghanaPostAddress, setGhanaPostAddress] = useState('')
    const [region, setRegion] = useState("")
    const [district, setDistrict] = useState("")
    const [postalAddress, setPostalAddress] = useState('')
    const [email, setEmail] = useState('')
    const [landlineNo, setLandlineNo] = useState('')
    const [mobileNo, setMobileNo] = useState('')
    const [mobileLinkedToCard, setMobileLinkedToCard] = useState("")


    const [professionalCategory, setProfessionalCategory] = useState('')
    const [agent, setAgent] = useState({
        agentName: "",
        agentLicence: "",
        agentBusiness: "",
        agentEmail: "",
        agentAddress: "",
        agentPhone: ""
    });
    const handleAgentChange = (e) => {
        const { name, value } = e.target;
        setAgent((prevAgent) => ({
            ...prevAgent,
            [name]: value
        }));
    };
    const [specialisation, setSpecialisation] = useState("")
    const [qualification, setQualification] = useState('')

    // Due to the dates present in there i have to make them independent states
    const [GhISDiplomaNo, setGhISDiplomaNo] = useState('')
    const [GhISDateOfIssue, setGhISDateOfIssue] = useState('')


    const [GLCLicenceNo, setGLCLicenceNo] = useState('')
    const [GLCDateOfIssue, setGLCDateOfIssue] = useState('')


    // NRP - NON-RESIDENT PRACTITIONER
    const [NRP, setNRP] = useState({
        NRPCountry: "",
        NRPRegulator: "",
        NRPLicenceNo: "",
    });
    const handleNRPChange = (e) => {
        const { name, value } = e.target;
        setNRP((prevNRP) => ({
            ...prevNRP,
            [name]: value
        }));
    };
    const [NRPDateOfIssue, setNRPDateOfIssue] = useState('')


    const [experience, setExperience] = useState('')
    const [operation, setOperation] = useState('')
    const [designatedOfficer, setDesignatedOfficer] = useState("")
    const [workTime, setWorkTime] = useState("")
    const [partTime, setPartTime] = useState({
        partTimeNoOfDays: '0',
        partTimeDays: [],
    })
    function handleCheckboxClick(event, day) {
        const checkbox = event.target;
        const isChecked = checkbox.checked;

        if (isChecked) {
            // Add the day to the result array if the checkbox is checked
            if (parseInt(partTime.partTimeNoOfDays) > partTime.partTimeDays.length) {
                partTime.partTimeDays.push(day);
            } else {
                checkbox.checked = false;
            }

        } else {
            // Remove the day from the result array if the checkbox is unchecked
            const index = partTime.partTimeDays.indexOf(day);
            if (index !== -1) {
                partTime.partTimeDays.splice(index, 1);
            }
        }
        // console.log(partTime)
    }
    const handlePartTimeChange = (e) => {

        setPartTime({
            partTimeNoOfDays: e.target.value,
            partTimeDays: []
        })
        const checkboxes = document.querySelectorAll("input[type=checkbox]")
        // console.log(checkboxes)

        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });

    };
    const [currentEmployment, setCurrentEmployment] = useState("")
    const [currentEmploymentName, setCurrentEmploymentName] = useState("")

    const [registeredName, setRegisteredName] = useState('')
    const [registrationNumber, setRegistrationNumber] = useState('')
    const [dateOfInc, setDateOfInc] = useState('')
    const [tinNo, setTinNo] = useState('')
    const [countryOfInc, setCountryOfInc] = useState("")
    const [companyAddress, setCompanyAddress] = useState('')
    const [companyPostalAddress, setCompanyPostalAddress] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [companyTelephone, setCompanyTelephone] = useState('')
    const [companyDirectors, setCompanyDirectors] = useState('')
    const [companyRegions, setCompanyRegions] = useState('')
    // const [companyOfficers, setCompanyOfficers] = useState('')
    const [employer, setEmployer] = useState('')

    const [threat, setThreat] = useState("")
    const [fraud, setFraud] = useState("")
    const [crime, setCrime] = useState("")
    const [revokation, setRevokation] = useState("")
    const [charges, setCharges] = useState("")
    const [threatDesc, setThreatDesc] = useState("")
    const [fraudDesc, setFraudDesc] = useState("")
    const [crimeDesc, setCrimeDesc] = useState("")
    const [revokationDesc, setRevokationDesc] = useState("")
    const [chargesDesc, setChargesDesc] = useState("")

    const [permitDoc, setPermitDoc] = useState()
    const [permitDocReview, setPermitDocReview] = useState('')
    const [passportPic, setPassportPic] = useState()
    const [passportPicReview, setPassportPicReview] = useState('')
    const [cardFront, setCardFront] = useState()
    const [cardFrontReview, setCardFrontReview] = useState('')
    const [cardBack, setCardBack] = useState()
    const [cardBackReview, setCardBackReview] = useState('')


    const passportInputRef = useRef()
    const permitInputRef = useRef()
    const cardFrontInputRef = useRef()
    const cardBackInputRef = useRef()

    const navigate = useNavigate();

    useEffect(() => {

        const rightElement = document.getElementById('create__right')

        const height = rightElement.offsetHeight * 0.9;

        setBtnHeight(height)


        const handleScroll = (event) => {
            const rightElement = document.getElementById('create__right')
            setBtnHeight(height + rightElement.scrollTop)
        };

        rightElement.addEventListener('scroll', handleScroll);
        return () => {
            rightElement.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const Headers = ['Personal Information', 'Contact Information', 'Category', 'Office Information', 'Criminal History',
        'File Upload', 'Declaration', 'Review']

    const switchHeader = () => {
        switch (pageNo) {
            case 0:
                return 'Personal Information'
            case 1:
                return 'Contact Information'
            case 2:
                return 'Category'
            case 3:
                return 'Office Information'
            case 4:
                return 'Criminal History'
            case 5:
            case 6:
            case 7:
            case 8:
                return 'File Upload'
            case 9:
                return 'Declaration'

            default:
                return 'Review'
        }
    }

    const switchForms = () => {
        switch (pageNo) {
            case 0:
                return <PersonalInfo
                    title={title}
                    setTitle={setTitle}
                    otherTitle={otherTitle}
                    setOtherTitle={setOtherTitle}
                    gender={gender}
                    setGender={setGender}
                    dob={dob}
                    setDob={setDob}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    middleName={middleName}
                    setMiddleName={setMiddleName}
                    lastName={lastName}
                    setLastName={setLastName}
                    ghanaCardNo={ghanaCardNo}
                    setGhanaCardNo={setGhanaCardNo}
                    setResidentCountry={setResidentCountry}
                    residentCountry={residentCountry}
                    nationalities={nationalities}
                    setNationalities={setNationalities}
                    residencePermit={residencePermit}
                    setResidencePermit={setResidencePermit}
                    residencePermitNo={residencePermitNo}
                    setResidencePermitNo={setResidencePermitNo}
                />
                break;

            case 1:
                return <ContactInfo
                    residentialAddress={residentialAddress}
                    setResidentialAddress={setResidentialAddress}
                    ghanaPostAddress={ghanaPostAddress}
                    setGhanaPostAddress={setGhanaPostAddress}
                    region={region}
                    setRegion={setRegion}
                    district={district}
                    setDistrict={setDistrict}
                    postalAddress={postalAddress}
                    setPostalAddress={setPostalAddress}
                    email={email}
                    setEmail={setEmail}
                    landlineNo={landlineNo}
                    setLandlineNo={setLandlineNo}
                    mobileNo={mobileNo}
                    setMobileNo={setMobileNo}
                    mobileLinkedToCard={mobileLinkedToCard}
                    setMobileLinkedToCard={setMobileLinkedToCard}
                />


            case 2:
                return <CategoryInfo
                    professionalCategory={professionalCategory}
                    setProfessionalCategory={setProfessionalCategory}
                    agent={agent}
                    handleAgentChange={handleAgentChange}
                    specialisation={specialisation}
                    setSpecialisation={setSpecialisation}
                    qualification={qualification}
                    setQualification={setQualification}

                    GhISDiplomaNo={GhISDiplomaNo}
                    setGhISDiplomaNo={setGhISDiplomaNo}
                    GhISDateOfIssue={GhISDateOfIssue}
                    setGhISDateOfIssue={setGhISDateOfIssue}

                    GLCLicenceNo={GLCLicenceNo}
                    setGLCLicenceNo={setGLCLicenceNo}
                    GLCDateOfIssue={GLCDateOfIssue}
                    setGLCDateOfIssue={setGLCDateOfIssue}

                    NRP={NRP}
                    handleNRPChange={handleNRPChange}
                    NRPDateOfIssue={NRPDateOfIssue}
                    setNRPDateOfIssue={setNRPDateOfIssue}


                    experience={experience}
                    setExperience={setExperience}
                    operation={operation}
                    setOperation={setOperation}
                    employer={employer}
                    setEmployer={setEmployer}
                    workTime={workTime}
                    setWorkTime={setWorkTime}
                    partTime={partTime}
                    handlePartTimeChange={handlePartTimeChange}
                    handleCheckboxClick={handleCheckboxClick}
                    currentEmployment={currentEmployment}
                    setCurrentEmployment={setCurrentEmployment}
                    currentEmploymentName={currentEmploymentName}
                    setCurrentEmploymentName={setCurrentEmploymentName}
                    designatedOfficer={designatedOfficer}
                    setDesignatedOfficer={setDesignatedOfficer}
                />


            case 3:
                if (operation.indexOf('Partnership') >= 0) {
                    return (
                        <CompanyInfo
                            //registeredName={registeredName}
                            //setRegisteredName={setRegisteredName}
                            //registrationNumber={registrationNumber}
                            //setRegistrationNumber={setRegistrationNumber}
                            //tinNo={tinNo}
                            //setTinNo={setTinNo}
                            //dateOfInc={dateOfInc}
                            //countryOfInc={countryOfInc}
                            //setCountryOfInc={setCountryOfInc}
                            //setDateOfInc={setDateOfInc}
                            companyAddress={companyAddress}
                            setCompanyAddress={setCompanyAddress}
                            companyPostalAddress={companyPostalAddress}
                            setCompanyPostalAddress={setCompanyPostalAddress}
                            companyEmail={companyEmail}
                            setCompanyEmail={setCompanyEmail}
                            companyTelephone={companyTelephone}
                            setCompanyTelephone={setCompanyTelephone}
                            //companyDirectors={companyDirectors}
                            //setCompanyDirectors={setCompanyDirectors}
                            companyRegions={companyRegions}
                            setCompanyRegions={setCompanyRegions}
                            //companyOfficers={companyOfficers}
                            //setCompanyOfficers={setCompanyOfficers}
                            //employer={employer}
                            //setEmployer={setEmployer}

                            professionalCategory={professionalCategory}

                        />
                    )
                } else {
                    return (
                        <SoleInfo
                            //registeredName={registeredName}
                            //setRegisteredName={setRegisteredName}
                            //registrationNumber={registrationNumber}
                            //setRegistrationNumber={setRegistrationNumber}
                            //tinNo={tinNo}
                            //setTinNo={setTinNo}
                            //dateOfInc={dateOfInc}
                            //setDateOfInc={setDateOfInc}
                            companyAddress={companyAddress}
                            setCompanyAddress={setCompanyAddress}
                            companyPostalAddress={companyPostalAddress}
                            setCompanyPostalAddress={setCompanyPostalAddress}
                            companyEmail={companyEmail}
                            setCompanyEmail={setCompanyEmail}
                            companyTelephone={companyTelephone}
                            setCompanyTelephone={setCompanyTelephone}

                            companyRegions={companyRegions}
                            setCompanyRegions={setCompanyRegions}

                            professionalCategory={professionalCategory}

                        />
                    )

                }



            case 4:
                return <CriminalHistory
                    threat={threat}
                    setThreat={setThreat}
                    fraud={fraud}
                    setFraud={setFraud}
                    crime={crime}
                    setCrime={setCrime}
                    charges={charges}
                    setCharges={setCharges}
                    revokation={revokation}
                    setRevokation={setRevokation}
                    threatDesc={threatDesc}
                    setThreatDesc={setThreatDesc}
                    fraudDesc={fraudDesc}
                    setFraudDesc={setFraudDesc}
                    crimeDesc={crimeDesc}
                    setCrimeDesc={setCrimeDesc}
                    chargesDesc={chargesDesc}
                    setChargesDesc={setChargesDesc}
                    revokationDesc={revokationDesc}
                    setRevokationDesc={setRevokationDesc}
                />

            case 5:
                return <UploadPassport
                    passportPicReview={passportPicReview}
                    setPassportPic={setPassportPic}
                    setPassportPicReview={setPassportPicReview}
                    passportInputRef={passportInputRef}
                />


            case 6:
                return <UploadPermit
                    permitDocReview={permitDocReview}
                    setPermitDoc={setPermitDoc}
                    setPermitDocReview={setPermitDocReview}
                    permitInputRef={permitInputRef}
                />


            case 7:
                return <UploadCardFront
                    cardFrontReview={cardFrontReview}
                    setCardFront={setCardFront}
                    setCardFrontReview={setCardFrontReview}
                    cardFrontInputRef={cardFrontInputRef}
                />


            case 8:
                return <UploadCardBack
                    cardBackReview={cardBackReview}
                    setCardBackReview={setCardBackReview}
                    setCardBack={setCardBack}
                    cardBackInputRef={cardBackInputRef}
                />


            case 9:

                return <Declaration
                    checkTrueInfo={checkTrueInfo}
                    setCheckTrueInfo={setCheckTrueInfo}

                />

            case 10:
                return <Template
                    data={{

                        //registeredName,
                        //registrationNumber,
                        //tinNo,
                        //dateOfInc,
                        //countryOfInc,
                        //companyDirectors,

                        companyAddress,
                        companyPostalAddress,
                        companyEmail,
                        companyTelephone,
                        companyRegions,



                        firstName,
                        middleName,
                        lastName,
                        title,
                        otherTitle,
                        gender,
                        dob,
                        ghanaCardNo,
                        residentCountry,
                        nationalities,
                        residencePermit,
                        residencePermitNo,

                        residentialAddress,
                        ghanaPostAddress,
                        postalAddress,
                        email,
                        mobileNo,
                        landlineNo,
                        mobileLinkedToCard,
                        region,
                        district,


                        professionalCategory,
                        specialisation,
                        qualification,
                        workTime,
                        operation,

                        employer,
                        agent,
                        GhISDiplomaNo,
                        GhISDateOfIssue,

                        GLCLicenceNo,
                        GLCDateOfIssue,

                        experience,
                        NRP,
                        NRPDateOfIssue,

                        partTime,
                        currentEmployment,
                        currentEmploymentName,
                        designatedOfficer,

                        threat,
                        threatDesc,
                        crime,
                        crimeDesc,
                        charges,
                        chargesDesc,
                        revokation,
                        revokationDesc,
                        fraud,
                        fraudDesc,

                        joined: dayjs().toString(),
                    }}
                />


            default:
                break;
        }
    }

    const setError = (message) => toast.error(message, { position: 'top-center' })
    const setSuccess = (message) => toast.success(message, { position: 'top-center' })

    const nextPage = () => {
        setPageNo(pageNo + 1)
        document.getElementById('create__right').scrollTo(0, 0)
    }

    const prevPage = () => {
        setErrorInput('')
        if (pageNo === 7) {
            if (nationalities !== 'Ghana') {
                setPageNo(6)
                return
            } else {
                setPageNo(5)
                return
            }
        }
        setPageNo(pageNo - 1)
        document.getElementById('create__right').scrollTo(0, 0)
    }

    function hasEmptyField(obj) {
        return _.some(obj, value => {
            // Exclude Date objects from being considered "empty"
            if (_.isDate(value)) {
                return false; // Date objects are never considered empty
            }

            // Check for empty values using lodash's isEmpty, excluding numbers, booleans, and dates
            return _.isEmpty(value) && !_.isNumber(value) && !_.isBoolean(value);
        });
    }

    function getErrorInput(pageInputs) {
        for (const key in pageInputs) {
            if (pageInputs.hasOwnProperty(key) && !pageInputs[key]) {
                //console.log(key)
                setErrorInput(key);
                return;
            }
        }
    }


    function handleNext() {
        if (pageNo === 0) {
            if (title === "" || dob === "" || gender === "" || firstName === "" || lastName === "" || ghanaCardNo === "" ||
                residentCountry === "" || nationalities === "" ||
                (residencePermit === "YES" && residencePermitNo === "")
            ) {
                getErrorInput({ title, firstName, lastName, gender, dob, ghanaCardNo, nationalities, residentCountry, residencePermit, residencePermitNo: residencePermit === "YES" ? residencePermitNo : "Pass Check" })
                setError("Please complete compulsory fields")
            } else {
                const isMinor = (new Date()).getFullYear() - new Date(dob).getFullYear() < 18;
                //console.log(isMinor)


                if (isMinor) {
                    setError("You should at least 18 years to qualify to apply")
                    setErrorInput('dob')

                } else {
                    const regex = /^GHA-[0-9A-Za-z]{9}-[0-9A-Za-z]$/;
                    if (regex.test(ghanaCardNo)) {
                        setPageNo(1)
                    } else {
                        setError("Enter Ghana Card ID in the correct format with no spaces");
                        setErrorInput('ghanaCardNo')
                    }
                }
            }
        } else if (pageNo === 1) {
            if (residentialAddress === "" || postalAddress === "" || ghanaPostAddress === "" || email === "" ||
                mobileNo === "" || region === "" || district === "" || mobileLinkedToCard === ""
            ) {
                getErrorInput({ residentialAddress, postalAddress, ghanaPostAddress, email, mobileNo, region, district, mobileLinkedToCard })
                setError("Please complete compulsory fields")
            } else {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (emailRegex.test(email)) {
                    if (mobileNo.length === 10) {
                        setPageNo(2)
                    } else {
                        setError("Make sure phone number is 10 digits")
                        setErrorInput('mobileNo')
                    }

                } else {
                    setError('Check the email address format')
                    setErrorInput('email')
                }

            }
        } else if (pageNo === 2) {
            if (professionalCategory === "" || qualification === "" || specialisation === "" ||
                workTime === "" || currentEmployment === "" || (professionalCategory === "Real Estate Broker" && operation === "")
            ) {
                setError("Please complete compulsory fields")
                getErrorInput({
                    professionalCategory,
                    qualification,
                    specialisation,
                    operation: professionalCategory === "Real Estate Broker" ? operation : "Pass Check",
                    workTime,
                    currentEmployment
                })
                // console.log("First check step")
            } else {
                if (
                    // All Required Broker Info is optional at the moment since no licence is not issued for affiliate brokers yet
                    //(professionalCategory === "Real Estate Agent" && hasEmptyField(agent)) ||
                    (operation === "Company or Partnership" && designatedOfficer === "") ||
                    (qualification === "I am a valuer licenced by GhIS" && hasEmptyField({ GhISDiplomaNo, GhISDateOfIssue })) ||
                    (qualification === "I am a lawyer licenced by GLC" && hasEmptyField({ GLCLicenceNo, GLCDateOfIssue })) ||
                    (qualification === "I am a real estate agency practitioner with prior experience" && experience === "") ||
                    (qualification === "I am a non-resident practitioner" && hasEmptyField({ ...NRP, NRPDateOfIssue })) ||
                    (workTime === "Part Time" && hasEmptyField(partTime)) ||
                    (currentEmployment === "YES" && currentEmploymentName === "")

                ) {
                    setError("Please complete compulsory fields")
                    getErrorInput(
                        {
                            designatedOfficer: operation === "Company or Partnership" ? designatedOfficer : "Pass Check",
                            GhISDiplomaNo: qualification === "I am a valuer licenced by GhIS" ? GhISDiplomaNo : 'Pass Check',
                            GhISDateOfIssue: qualification === "I am a valuer licenced by GhIS" ? GhISDateOfIssue : 'Pass check',
                            GLCLicenceNo: qualification === "I am a lawyer licenced by GLC" ? GLCLicenceNo : 'Pass Check',
                            GLCDateOfIssue: qualification === "I am a lawyer licenced by GLC" ? GLCDateOfIssue : 'Pass Check',
                            experience: qualification === "I am a real estate agency practitioner with prior experience" ? experience : "Pass Check",
                            NRPCountry: qualification === "I am a non-resident practitioner" ? NRP.NRPCountry : "Pass Check",
                            NRPRegulator: qualification === "I am a non-resident practitioner" ? NRP.NRPRegulator : "Pass Check",
                            NRPLicenceNo: qualification === "I am a non-resident practitioner" ? NRP.NRPLicenceNo : "Pass Check",
                            NRPDateOfIssue: qualification === "I am a non-resident practitioner" ? NRPDateOfIssue : "Pass Check",
                            currentEmploymentName: currentEmployment === "YES" ? currentEmploymentName : "Pass Check",
                        }
                    )
                    // console.log("Second check step")
                } else {
                    if (parseInt(partTime.partTimeNoOfDays === 0)) {
                        setError(`Please Select No Of Days you operate`)
                        setErrorInput('partTimeNoOfDays')
                    } else {
                        if (parseInt(partTime.partTimeNoOfDays) === partTime.partTimeDays.length) {

                            if (operation === "Employee of a company") {
                                setPageNo(3)
                                /*
                                if (employer === "") {
    
                                    setError("Please complete compulsory fields")
                                    // console.log("Third check step")
                                } else {
                                    setPageNo(4)
                                }
                                */
                            } else {
                                setPageNo(3)
                            }


                        } else {
                            setError(`Please Tick ${partTime.partTimeNoOfDays} days`)
                            setErrorInput('partTimeNoOfDays')
                        }
                    }
                }
            }
        } else if (pageNo === 3) {
            if (operation.indexOf('Partnership') >= 0) {
                if (companyAddress === "" || companyPostalAddress === "" || companyEmail === "" ||
                    companyTelephone === "" || (professionalCategory === "Real Estate Broker" && companyRegions === "")
                ) {
                    setError("Please complete compulsory fields")
                    getErrorInput(
                        {
                            companyAddress, companyPostalAddress, companyEmail, companyTelephone,
                            companyRegions: professionalCategory === "Real Estate Broker" ? companyRegions : "Pass Check"
                        })
                } else {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (emailRegex.test(companyEmail)) {
                        setPageNo(4)
                    } else {
                        setError('Check the email address format')
                        setErrorInput('companyEmail')
                    }
                }
            } else {
                if (companyAddress === "" || companyPostalAddress === "" || companyEmail === "" ||
                    companyTelephone === "" || (professionalCategory === "Real Estate Broker" && companyRegions === "")) {
                    setError('Please complete compulsory fields')
                    getErrorInput(
                        {
                            companyAddress, companyPostalAddress, companyEmail, companyTelephone,
                            companyRegions: professionalCategory === "Real Estate Broker" ? companyRegions : "Pass Check"
                        })
                } else {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (emailRegex.test(companyEmail)) {
                        setPageNo(4)
                    } else {
                        setError('Check the email address format')
                        setErrorInput('companyEmail')
                    }
                }
            }
        } else if (pageNo === 4) {
            if (threat === "" || fraud === "" || crime === "" || charges === "" || revokation === "") {
                setError('Please complete compulsory fields')
                getErrorInput({ threat, fraud, crime, charges, revokation })
            } else {
                if (
                    (threat === "YES" && threatDesc === "") ||
                    (fraud === "YES" && fraudDesc === "") ||
                    (crime === 'YES' && crimeDesc === "") ||
                    (revokation === "YES" && revokationDesc === "") ||
                    (charges === "YES" && chargesDesc === "")

                ) {
                    getErrorInput({
                        threatDesc: threat === "YES" ? threatDesc : 'Pass Check',
                        fraudDesc: fraud === "YES" ? fraudDesc : 'Pass Check',
                        crimeDesc: crime === 'YES' ? crimeDesc : 'Pass Check',
                        revokationDesc: revokation === "YES" ? revokationDesc : 'Pass Check',
                        chargesDesc: charges === "YES" ? chargesDesc : 'Pass Check',

                    })
                    setError('Please complete compulsory fields')
                } else {
                    setPageNo(5)
                }
            }
        } else if (pageNo === 5) {
            if (passportPic === undefined) {
                setError("Upload Passport Picture")
            } else {
                if (nationalities !== 'Ghana') {
                    setPageNo(6)
                } else {
                    setPageNo(7)
                }

            }
        } else if (pageNo === 6) {
            if (permitDoc === undefined) {
                setError('Upload your resident permit')
            } else {
                setPageNo(7)
            }

        } else if (pageNo === 7) {
            if (cardFront === undefined) {
                setError('Upload Front page of your card')
            } else {
                setPageNo(8)
            }
        } else if (pageNo === 8) {
            if (cardBack === undefined) {
                setError("upload Back of your Card")
            } else {
                setPageNo(9)
            }
        } else if (pageNo === 9) {
            if (!checkTrueInfo) {
                setError("Please tick boxes to continue")
            } else {
                setPageNo(10)
            }
        }
    }


    async function handleSubmit() {

        let now = dayjs()
        // console.log(now)

        if (!checkTrueInfo) {
            setError("Please tick boxes to continue")
            return
        }

        setShowSpinner(true)

        const emailExists = await checkEmailExists(email)

        if (emailExists) {
            setError("Email has already been used by another account")
            setShowSpinner(false)
            return
        }


        const companyData = {

            //registeredName,
            //registrationNumber,
            //tinNo,
            //dateOfInc,
            //countryOfInc,
            //companyDirectors,

            companyAddress,
            companyPostalAddress,
            companyEmail,
            companyTelephone,
            companyRegions
        }

        const userData = {
            firstName,
            middleName,
            lastName,
            title,
            otherTitle,
            gender,
            dob,
            ghanaCardNo,
            residentCountry,
            nationalities,
            residencePermit,
            residencePermitNo,

            residentialAddress,
            ghanaPostAddress,
            postalAddress,
            email,
            mobileNo,
            landlineNo,
            mobileLinkedToCard,
            region,
            district,


            professionalCategory,
            specialisation,
            qualification,
            workTime,
            operation,

            employer,
            agent,
            GhISDiplomaNo,
            GhISDateOfIssue,

            GLCLicenceNo,
            GLCDateOfIssue,

            experience,
            NRP,
            NRPDateOfIssue,

            partTime,
            currentEmployment,
            currentEmploymentName,
            designatedOfficer,

            threat,
            threatDesc,
            crime,
            crimeDesc,
            charges,
            chargesDesc,
            revokation,
            revokationDesc,
            fraud,
            fraudDesc,

            joined: now.toString(),
        }


        createUser(userData,
            companyData,
            permitDoc,
            passportPic,
            cardFront,
            cardBack,
            setShowSpinner,
            setError,
            setSuccess,
            setShowPayment,
            setUserID
        )


    }

    return (
        <div className="create__wrapper">
            <PaymentPage
                showModal={showPayment}
                hideModal={() => setShowPayment(false)}
                category={professionalCategory}
                qualification={qualification}
                email={email}
                userID={userID}
            />
            <Requirements
                showModal={showRequirements}
                hideModal={() => setShowRequirements(false)}
            />
            <div className='create__container create--signup'>
                <div className='create create--signup' id="create">
                    <div
                        className="create__left"
                        style={showPayment ? inlineStyle : {}}
                    >

                        <h2 className="create__left__header">Welcome to Real Estate Agency Council Hub (REACH)</h2>

                        <img src={Logo} alt="Logo of company" />

                        <ul>
                            {
                                Headers.map((item, index) =>
                                    <li className={item === switchHeader(pageNo) ? 'create__left__selected' : ''}>
                                        {item}
                                    </li>)
                            }
                        </ul>

                        <button
                            className='btn create__left__btn'
                            onClick={() => navigate('/')}
                        >
                            Sign in
                        </button>
                    </div>

                    <div
                        className="create__right"
                        id='create__right'
                    >

                        <div className="create__right__phonelogo">
                            <img src={Logo} alt="Logo of company" />
                        </div>

                        <h2>Licence Application Form</h2>

                        <div className="create__right__banner">
                            <div className='create__right__banner__icon'>
                                <GiClover />
                            </div>
                            <div className="create__right__banner__text">
                                {showPayment ? "Payment Page" : switchHeader()}
                            </div>

                        </div>

                        {
                            showSpinner &&
                            <div className="create__right__form">
                                <Spinner showSpinner={showSpinner} />
                            </div>
                        }

                        <div className="create__right__form" style={showSpinner || showPayment ? inlineStyle : {}}>

                            {
                                switchForms()
                            }


                            <div className="create__right__form__btnbox">
                                {
                                    pageNo ? (
                                        <button
                                            class="btn create__right__form__btn"
                                            onClick={(e) => pageNo === 0 ? {} : prevPage()}
                                            disabled={pageNo === 0}
                                        >
                                            <AiOutlineArrowLeft className='create__right__form__btn__icon' />
                                            <span>Previous</span>
                                        </button>
                                    ) : (<div />)
                                }

                                <button
                                    class="btn create__right__form__btn"
                                    onClick={(e) => pageNo === 10 ? handleSubmit() : handleNext()}
                                >
                                    <span>{pageNo === 10 ? 'Pay & Apply' : 'Next'}</span>
                                    <AiOutlineArrowRight className='create__right__form__btn__icon' />
                                </button>
                            </div>
                        </div>

                        <div
                            className="create__right__form__redirect"
                            onClick={() => navigate('/')}
                        >
                            Have an account? Log in
                        </div>

                        <IoMdArrowDropdownCircle
                            className='create__right__dropdown'
                            style={{ top: btnHeight }}
                        />

                    </div>
                </div>
                <ToastContainer />
            </div>
            <Footer />
        </div>
    )
}


const UploadPassport = (props) => {

    function handleImageChange(e) {
        props.setPassportPic(e.target.files[0])
        props.setPassportPicReview(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>
            <div className="create__right__form__passport">
                <input
                    type="file"
                    accept=".png, .jpg"
                    hidden
                    style={{ width: 0, display: 'none' }}
                    id="passport"
                    required
                    onChange={handleImageChange}
                    ref={props.passportInputRef}
                />
                <h3>Click image to upload</h3>
                <label for="passport">
                    <div className="create__right__form__passport__picbox">
                        <img src={props.passportPicReview || Avatar} alt="Passport Pic of Applicant" />
                        <p>Passport Picture</p>
                    </div>
                </label>
            </div>
        </form>
    )
}

const UploadPermit = (props) => {

    function handleImageChange(e) {
        props.setPermitDoc(e.target.files[0])
        props.setPermitDocReview(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>
            <div className="create__right__form__passport">

                <input
                    type="file"
                    accept=".png, .jpg"
                    hidden
                    style={{ width: 0, display: 'none' }}
                    id="passport"
                    required
                    onChange={handleImageChange}
                    ref={props.permitInputRef}
                />
                <h3>Click image to upload</h3>
                <label for="passport">
                    <div className="create__right__form__passport__picbox">
                        <img src={props.permitDocReview || DocPreview} alt="Permit Document" />
                        <p>Residence Permit</p>

                    </div>
                </label>
            </div>
        </form>
    )
}

const UploadCardFront = (props) => {

    function handleImageChange(e) {
        props.setCardFront(e.target.files[0])
        props.setCardFrontReview(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>
            <div className="create__right__form__passport">
                <input
                    type="file"
                    accept=".png, .jpg"
                    hidden
                    style={{ width: 0, display: 'none' }}
                    id="passport"
                    required
                    onChange={handleImageChange}
                    ref={props.cardFrontInputRef}
                />
                <h3>Click image to upload</h3>
                <label for="passport">
                    <div className="create__right__form__passport__picbox">
                        <img src={props.cardFrontReview || Cardfront} alt="Passport Pic of Applicant" />
                        <p>Front of Ghana Card</p>
                    </div>
                </label>
            </div>
        </form>
    )
}

const UploadCardBack = (props) => {

    function handleImageChange(e) {
        props.setCardBack(e.target.files[0])
        props.setCardBackReview(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>
            <div className="create__right__form__passport">
                <input
                    type="file"
                    accept=".png, .jpg"
                    hidden
                    style={{ width: 0, display: 'none' }}
                    id="passport"
                    required
                    onChange={handleImageChange}
                    ref={props.cardBackInputRef}
                />
                <h3>Click image to upload</h3>
                <label for="passport">
                    <div className="create__right__form__passport__picbox">
                        <img src={props.cardBackReview || Cardback} alt="Passport Pic of Applicant" />
                        <p>Back Of Ghana Card</p>
                    </div>
                </label>
            </div>
        </form>
    )
}


export default CreateAccount
