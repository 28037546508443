import { getFirestore, addDoc, collection, doc, updateDoc, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios";
import { Paystack } from "../shared";
import _ from "lodash";
import HandlePayment from "../shared/Paystack";

function getInvoice(data) {
  //console.log(data)
  let amount = 0;
  let name = "Annual Registration - Agent";
  let service_code = "AR002";

  if (data.professionalCategory === "Real Estate Broker") {
    amount = 2850;
    name = "Annual Registration - Broker";
    service_code = "AR001";
  } else {
    amount = 1350;
    name = "Annual Registration - Agent";
    service_code = "AR002";
  }

  if (data.qualification === "I am a non-resident practitioner") {
    amount += 1000;

    if (data.category === "Real Estate Broker") {
      name = "Foreign Qualified -Broker";
      service_code = "AR003";
    } else {
      name = "Foreign Qualified- Agent";
      service_code = "AR004";
    }
  }
  return { amount: amount + ".00", name, service_code };
}

function generateUniqueId() {
  const timestamp = Date.now();
  const randomPart = Math.floor(Math.random() * 10000);
  const uniqueId = timestamp + randomPart;
  return uniqueId;
}

function formatPhoneNumber(phoneNumber) {
  // Remove leading zeros
  phoneNumber = phoneNumber.replace(/^0+/, "");

  // Add the country code (233) if the length is less than 10
  if (phoneNumber.length < 10) {
    phoneNumber = "233" + phoneNumber;
  }

  return phoneNumber;
}

async function createUser(
  userData,
  companyData,
  permit,
  passport,
  cardFront,
  cardBack,
  setShowSpinner,
  setError,
  setSuccess,
  setPayment,
  setUserID
) {
  const firestore = getFirestore();
  try {
    // Upload documents to Firebase Storage (if provided)
    let documentUploads = [];
    const files = [passport, permit, cardFront, cardBack]; // Array of file objects
    uploadMultipleFiles(files)
      .then(async (downloadURLs) => {
        documentUploads = downloadURLs;
        // console.log("Download URLs:", downloadURLs);

        // Save company data to Firestore (if provided)

        let companyID;
        if (!_.isEmpty(companyData)) {
          const companyRef = await addDoc(
            collection(firestore, "companies"),
            companyData
          );
          console.log("Copmpany Document written with ID: ", companyRef.id);
          companyID = companyRef.id;
        } else {
          companyID = "";
        }

        // Save user data to Firestore with document links and company ID

        const finalUserData = {
          ...userData,
          passportLink: documentUploads[0],
          permitLink: documentUploads[1],
          cardFrontLink: documentUploads[2],
          cardBackLink: documentUploads[3],
          companyID: companyID, // Ensure companyID is null if no company
          //paymentInvoice: invoiceNo,
        };

        const docRef = await addDoc(
          collection(firestore, "users"),
          finalUserData
        );
        //console.log("User Document written with ID: ", docRef.id);

        setUserID(docRef.id);

        const id = generateUniqueId();
        const invoice = getInvoice(userData);

        const res = await fetch(
          `/.netlify/functions/createinvoice?phonenumber=${userData.mobileNo}&amount=${invoice.amount}&name=${invoice.name}&service_code=${invoice.service_code}&uniqueid=${id}&email=${userData.email}&firstname=${userData.firstName}&lastname=${userData.lastName}`
        );
        res.json().then(async (response) => {
          // console.log(response);

          if (response.message === "Success") {
            const invoiceNo = response.invoice_number;
            //console.log(invoiceNo);

            const updateRef = doc(firestore, "users", docRef.id);

            // Set the "capital" field of the city 'DC'
            try {

              await updateDoc(updateRef, {
                paymentInvoice: invoiceNo,
              });
              
            } catch (error) {
              setShowSpinner(false);
              setError("Data Saved but cannot start Payment Procedure. Contact Us");
              console.log(error)
            }
            

            setShowSpinner(false);

            setSuccess("Account Creation Successful");

            //setPayment(true);
            window.location.href = response.checkout_url;
          } else {
            setShowSpinner(false);
            setError("Cannot start Payment Procedure. Try again");
            console.log(response);
          }
        });
      })
      .catch((error) => {
        setShowSpinner(false);
        setError("Error creating Account. Try again");
        console.error("Error uploading multiple files:", error);
      });
  } catch (error) {
    setShowSpinner(false);
    setError("Error creating Account. Try again");
    console.log(error);
    // throw error;
  }
}

// Helper function to upload a document to Firebase Storage
async function uploadFileAndGetURL(file, fileName) {
  if (!file || file === undefined) {
    //console.log("File is empty, skipping upload.");
    return ""; // Return an empty string if the file is empty
  }

  const storage = getStorage();

  // Create a unique file name by appending the current timestamp
  const uniqueFileName = `${Date.now()}_${fileName}`;
  const storageRef = ref(storage, uniqueFileName);

  try {
    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);
    //console.log("File uploaded successfully");

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    //console.log("Download URL:", downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    // throw error;
  }
}

async function uploadMultipleFiles(files) {
  console.log(files);
  const downloadURLs = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    if (!file || file === undefined) {
      downloadURLs.push("");
    } else {
      const fileName = file.name; // Use the original file name

      try {
        // Upload the file and get the download URL
        const downloadURL = await uploadFileAndGetURL(file, fileName);
        // Push the download URL into the array
        downloadURLs.push(downloadURL);
      } catch (error) {
        console.error(`Error uploading file ${fileName}:`, error);
      }
    }
  }

  return downloadURLs;
}

async function checkEmailExists(email) {
  const firestore = getFirestore();
  const usersRef = collection(firestore, 'users');

  try {
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
  } catch (error) {
    console.error('Error  checking email  existence:', error);
    return false;
  }
}

export { createUser, checkEmailExists };
