import React, { useContext } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import Sidebar from './Sidebar';
import Header from './Header';
import { Footer } from '../shared';
import Logo from '../../img/Logo-nobg.png'
import HeadImg from '../../img/real-estate-agent.png'


function firebaseTimestampToDateString(timestampObject) {
    const timestamp = timestampObject.seconds + (timestampObject.nanoseconds / 1000000000); // Convert nanoseconds to seconds
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}


function Dashboard() {

    const { userData } = useContext(AppDataContext)
    

    return (
        <div className="home">
            <Sidebar />
            <Header />
            <div className="home__main">
                <div className="home__main__header">
                    <p>Your General Information Area</p>
                    <h3>Dashboard</h3>

                </div>

                <div className="home__main__content">
                    <div className='dashboard'>
                        <div className="dashboard__container">

                            <div className="dashboard__header">
                                <div className="dashboard__header__text">
                                    <h4>Welcome to your Dashboard Area</h4>
                                    <p>Real Estate Agency Council Hub Ghana</p>
                                </div>

                                <div className="dashboard__header__img">
                                    <img src={HeadImg} alt="Page Icon" />
                                </div>

                            </div>

                            <div className="dashboard__content">
                                <div className="dashboard__content__section">
                                    <div className="dashboard__content__label">
                                        Personal Info
                                    </div>
                                    <div className="dashboard__content__main">
                                        <PersonalInfo data={userData} />
                                    </div>

                                </div>

                                <div className="dashboard__content__section">
                                    <div className="dashboard__content__label">
                                        Contact Info
                                    </div>
                                    <div className="dashboard__content__main">
                                        <ContactInfo data={userData} />
                                    </div>

                                </div>

                                <div className="dashboard__content__section">
                                    <div className="dashboard__content__label">
                                        Category Info
                                    </div>
                                    <div className="dashboard__content__main">
                                        <CategoryInfo data={userData} />
                                    </div>

                                </div>
                                {
                                    userData.operation === "Company or Partnership" &&
                                    <div className="dashboard__content__section">
                                        <div className="dashboard__content__label">
                                            Company Info
                                        </div>
                                        <div className="dashboard__content__main">
                                            <CompanyInfo data={userData} />
                                        </div>

                                    </div>

                                }

                                {
                                    userData.operation === "Self-employed" &&
                                    <div className="dashboard__content__section">
                                        <div className="dashboard__content__label">
                                            Sole Proprietorship
                                        </div>
                                        <div className="dashboard__content__main">
                                            <SoleInfo data={userData} />
                                        </div>

                                    </div>

                                }
                                
                                <div className="dashboard__content__section">
                                    <div className="dashboard__content__label">
                                        Criminal History
                                    </div>
                                    <div className="dashboard__content__main">
                                        <CriminalHistory data={userData} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer isHome/>
            </div>
        </div>
    )
}

export default Dashboard

const ViewItem = (props) => {
    return (
        <div className="dashboard__content__item">
            <h4>{props.label}</h4>
            <p>{props.value}</p>
        </div>
    )
}


const PersonalInfo = (props) => {
    return (
        <>

            <ViewItem
                label="Title"
                value={props.data.title}
            />

            <ViewItem
                label="First Name"
                value={props.data.firstName}
            />

            <ViewItem
                label="Middle Name"
                value={props.data.middleName}
            />

            <ViewItem
                label="Last Name"
                value={props.data.lastName}
            />

            <ViewItem
                label="Gender"
                value={props.data.gender}
            />

            <ViewItem
                label="Date Of Birth"
                value={firebaseTimestampToDateString(props.data.dob)}
            />

            <ViewItem
                label="Ghana Card Number"
                value={props.data.ghanaCardNo}
            />

            <ViewItem
                label="Country Of Citizenship"
                value={props.data.nationalities}
            />

            <ViewItem
                label="Country of Residence"
                value={props.data.residentCountry}
            />

            <ViewItem
                label="Do you have Residence Work Permit?"
                value={props.data.residencePermit}
            />

            <ViewItem
                label="Resident Work Permit Number"
                value={props.data.residencePermitNo}
            />

        </>
    )
}

const ContactInfo = (props) => {
    return (
        <>
            <ViewItem
                label="Residential Address"
                value={props.data.residentialAddress}
            />

            <ViewItem
                label="Region"
                value={props.data.region}
            />

            <ViewItem
                label="District"
                value={props.data.district}
            />

            <ViewItem
                label="Postal Address"
                value={props.data.postalAddress}
            />

            <ViewItem
                label="Email Address"
                value={props.data.email}
            />

            <ViewItem
                label="Landline Number"
                value={props.data.landlineNo}
            />

            <ViewItem
                label="Mobile Number"
                value={props.data.mobileNo}
            />

            <ViewItem
                label="Is mobile linked to your Ghana Card ?"
                value={props.data.mobileLinkedToCard}
            />
        </>
    )
}

const CategoryInfo = (props) => {
    return (
        <>
            <ViewItem
                label="Professional category"
                value={props.data.professionalCategory}
            />

            {
                props.data.professionalCategory === "Real Estate Agent" &&
                <>

                    <ViewItem
                        label="Name of affiliate"
                        value={props.data.agent.agentName}
                    />

                    <ViewItem
                        label="Licence number of affiliate"
                        value={props.data.agent.agentLicence}
                    />

                    <ViewItem
                        label="Business/Company Name of Broker"
                        value={props.data.agent.agentBusiness}
                    />

                    <ViewItem
                        label="Email address of the Broker"
                        value={props.data.agent.agentEmail}
                    />

                    <ViewItem
                        label="Business address of the Broker(GPS)"
                        value={props.data.agent.agentAddress}
                    />

                    <ViewItem
                        label="Phone Number of the Broker"
                        value={props.data.agent.agentPhone}
                    />

                </>
            }


            <ViewItem
                label="Specialisation"
                value={props.data.specialisation}
            />

            <ViewItem
                label="Qualification"
                value={props.data.qualification}
            />


            {
                props.data.qualification === "I am a valuer licenced by GhIS" &&
                <>

                    <ViewItem
                        label="Diploma Number"
                        value={props.data.GhISDiplomaNo}
                    />

                    <ViewItem
                        label="Date Of Issue"
                        value={firebaseTimestampToDateString(props.data.GhISDateOfIssue)}
                    />

                    
                </>

            }

            {
                props.data.qualification === "I am a lawyer licenced by GLC" &&
                <>

                    <ViewItem
                        label="Licence Number"
                        value={props.data.GLCLicenceNo}
                    />

                    <ViewItem
                        label="Date Of Issue"
                        value={firebaseTimestampToDateString(props.data.GLCDateOfIssue)}
                    />

                   
                </>

            }

            {
                props.data.qualification === "I am a real estate agency practitioner with prior experience" &&
                <>

                    <ViewItem
                        label="Years of experience"
                        value={props.data.experience}
                    />


                </>

            }

            {
                props.data.qualification === "I am a non-resident practitioner" &&
                <>

                    <ViewItem
                        label="Country Of Practice"
                        value={props.data.NRP.NRPCountry}
                    />

                    <ViewItem
                        label="Institution regulating/Issuing licence"
                        value={props.data.NRP.NRPRegulator}
                    />

                    <ViewItem
                        label="Licence Number"
                        value={props.data.NRP.NRPLicenceNo}
                    />

                    <ViewItem
                        label="Date Of Issue"
                        value={firebaseTimestampToDateString(props.data.NRPDateOfIssue)}
                    />

                </>

            }

            <ViewItem
                label="Mode of operation"
                value={props.data.operation}
            />

            {

                props.data.operation === "Company or Partnership" &&
                <ViewItem
                    label="Designated Officer"
                    value={props.data.designatedOfficer}
                />
            }

            {
                props.data.operation === "Employee of a company" &&
                <>

                    <ViewItem
                        label="Employer"
                        value={props.data.employer}
                    />
                </>

            }

            <ViewItem
                label="Full time or part time"
                value={props.data.workTime}
            />

            {
                props.data.workTime === "Part Time" &&
                <>

                    <ViewItem
                        label="No Days user works in a week"
                        value={props.data.partTime.partTimeNoOfDays}
                    />

                    <ViewItem
                        label="Days user works in a week"
                        value={props.data.partTime.partTimeDays.join(", ")}
                    />
                </>

            }

            <ViewItem
                label="Currently Employed"
                value={props.data.currentEmployment}
            />

            {
                props.data.currentEmployment === "YES" &&
                <ViewItem
                    label="Current Employed Company"
                    value={props.data.currentEmploymentName}
                />
            }

        </>
    )
}

const CompanyInfo = (props) => {
    return (
        <>
            <ViewItem
                label="Registered name of the company"
                value={props.data.registeredName}
            />

            <ViewItem
                label="Registration Number of the company"
                value={props.data.registrationNumber}
            />

            <ViewItem
                label="Date of incorporation"
                //value={props.data.dateOfInc.seconds}
                value={"Data Inconsistent"}
            />

            <ViewItem
                label="Country Of Incorporation"
                value={props.data.countryOfInc}
            />

            <ViewItem
                label="TIN number"
                value={props.data.tinNo}
            />

            <ViewItem
                label="Physical address of the company"
                value={props.data.companyAddress}
            />

            <ViewItem
                label="Postal address of the company"
                value={props.data.companyPostalAddress}
            />

            <ViewItem
                label="Email address of the company"
                value={props.data.companyEmail}
            />

            <ViewItem
                label="Office telephone number"
                value={props.data.companyTelephone}
            />

            <ViewItem
                label="Directors, Members or Partners"
                value={props.data.companyDirectors}
            />

            <ViewItem
                label="Regions of operation"
                value={props.data.companyRegions}
            />
        </>
    )
}

const SoleInfo = (props) => {
    return (
        <>
            <ViewItem
                label="Registered name of the company"
                value={props.data.registeredName}
            />


            <ViewItem
                label="Physical address of the company"
                value={props.data.companyAddress}
            />



            <ViewItem
                label="Postal address of the company"
                value={props.data.companyPostalAddress}
            />

            <ViewItem
                label="Email address of the company"
                value={props.data.companyEmail}
            />

            <ViewItem
                label="Office telephone number"
                value={props.data.companyTelephone}
            />
        </>
    )
}

const CriminalHistory = (props) => {
    return (
        <>
            <ViewItem
                label="Threat activities"
                value={props.data.threat}
            />
            {
                props.data.threat === "YES" &&
                <ViewItem
                    label="Threat Description"
                    value={props.data.threatDesc}
                />
            }


            <ViewItem
                label="Fraud Activities"
                value={props.data.fraud}
            />

            {
                props.data.fraud === "YES" &&
                <ViewItem
                    label="Fraud Description"
                    value={props.data.fraudDesc}
                />
            }

            <ViewItem
                label="Crime Acivities"
                value={props.data.crime}
            />

            {
                props.data.crime === "YES" &&
                <ViewItem
                    label="Fraud Description"
                    value={props.data.crimeDesc}
                />
            }

            <ViewItem
                label="Licence Revokation"
                value={props.data.revokation}
            />

            {
                props.data.revokation === "YES" &&
                <ViewItem
                    label="Fraud Description"
                    value={props.data.revokationDesc}
                />
            }


            <ViewItem
                label="Pending Charges"
                value={props.data.charges}
            />

            {
                props.data.charges === "YES" &&
                <ViewItem
                    label="Fraud Description"
                    value={props.data.chargesDesc}
                />
            }


        </>
    )
}
