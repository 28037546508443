import React, { useContext, useState, useEffect } from 'react'
import DatePicker from 'react-date-picker';
import { AppDataContext } from '../../Contexts/AppDataContext';

const years = [
    1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 1909,
    1910, 1911, 1912, 1913, 1914, 1915, 1916, 1917, 1918, 1919,
    1920, 1921, 1922, 1923, 1924, 1925, 1926, 1927, 1928, 1929,
    1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938, 1939,
    1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949,
    1950, 1951, 1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959,
    1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969,
    1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979,
    1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989,
    1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
    2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
    2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
    2020, 2021, 2022, 2023, 2024
];

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const daysOfMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];

function Input(props) {

    const { errorInput, setErrorInput } = useContext(AppDataContext)


    return (
        <div class="create__right__form__group">

            <label
                for={props.id}
                className="create__right__form__label"
            >
                {props.label}
            </label>

            <div className="create__right__form__group__inputbox">

                <div className="create__right__form__input__icon">
                    {props.children}
                </div>
                {
                    props.type === 'obj' ?
                        <input
                            type={props?.checktype === "number" ? "number" : "text"}
                            className={`create__right__form__input ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                            placeholder={props.placeholder}
                            id={props.id}
                            name={props.id}
                            required
                            value={props.value}
                            onChange={props.setValue}

                        /> :
                        <input
                            type={props?.checktype === "number" ? "number" : "text"}
                            className={`create__right__form__input ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                            placeholder={props.placeholder}
                            id={props.id}
                            name={props.id}
                            required
                            value={props.value}
                            onChange={(e) => props.setValue(e.target.value)}

                        />
                }

            </div>

        </div>
    )
}

function LetterInput(props) {

    const { errorInput, setErrorInput } = useContext(AppDataContext)

    const handleLettersOnlyChange = (event) => {
        const newValue = event.target.value.replace(/[^a-zA-Z]/g, "");
        props.setValue(newValue);
    };

    return (
        <div class="create__right__form__group">

            <label
                for={props.id}
                className="create__right__form__label"
            >
                {props.label}
            </label>

            <div className="create__right__form__group__inputbox">

                <div className="create__right__form__input__icon">
                    {props.children}
                </div>
                {
                    props.type === 'obj' ?
                        <input
                            type="text"
                            pattern='[a-zA-Z]+'
                            className={`create__right__form__input ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                            placeholder={props.placeholder}
                            id={props.id}
                            name={props.id}
                            required
                            value={props.value}
                            onChange={handleLettersOnlyChange}

                        /> :
                        <input
                            type="text"
                            pattern='[a-zA-Z]+'
                            className={`create__right__form__input ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                            placeholder={props.placeholder}
                            id={props.id}
                            name={props.id}
                            required
                            value={props.value}
                            onChange={(e) => handleLettersOnlyChange(e)}

                        />
                }

            </div>

        </div>
    )
}

function Select(props) {

    const { errorInput, setErrorInput } = useContext(AppDataContext)

    return (
        <div class="create__right__form__group">

            <label
                for={props.id}
                className="create__right__form__label"
            >
                {props.label}
            </label>

            <div className="create__right__form__group__inputbox">

                <div className="create__right__form__input__icon">
                    {props.children}
                </div>

                {
                    props.type === "obj" ?
                        <select
                            className={`create__right__form__input ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                            onChange={props.setValue}
                            value={props.value}
                            id={props.id}
                            name={props.id}
                            style={props.value === "" ? { color: 'grey', fontWeight: 'bold' } : {}}
                        >
                            <option hidden>{props.placeholder}</option>
                            {
                                props.list.map((item, index) => <option value={item}>{item}</option>)
                            }
                        </select>
                        :
                        <select
                            className={`create__right__form__input ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                            onChange={(e) => props.setValue(e.target.value)}
                            value={props.value}
                            id={props.id}
                            name={props.id}
                            style={props.value === "" ? { color: 'grey', fontWeight: 'bold' } : {}}
                        >
                            <option hidden>{props.placeholder}</option>
                            {
                                props.list.map((item, index) => <option value={item}>{item}</option>)
                            }
                        </select>
                }
            </div>
        </div>
    )
}

function DateInput(props) {
    const [year, setYear] = useState(1900);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(1);
    const [daysLength, setDaysLength] = useState(31)

    const { errorInput, setErrorInput } = useContext(AppDataContext)

    function isLeapYear(year) {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    useEffect(() => {

        if (month === 1) {
            if (isLeapYear(year)) {
                setDaysLength(29)
                setDay(day > 29 ? 1 : day)
            } else {
                setDaysLength(28)
                setDay(day > 28 ? 1 : day)
            }

        } else if (month === 3 || month === 5 || month === 8 || month === 10) {
            setDaysLength(30)
            setDay(day > 30 ? 1 : day)
        } else {
            setDaysLength(31)
        }
        const newDate = new Date(year, month, day); // Month is zero-indexed
        if (year === 1900) {
            return
        }
        props.setValue(newDate);

        // console.log(newDate);
    }, [year, month, day]);

    const handleYearChange = (e) => setYear(e.target.value);
    const handleMonthChange = (e) => {
        // console.log(e.target.value)
        setMonth(Number(e.target.value));
    }
    const handleDayChange = (e) => setDay(e.target.value);


    function setDateInput(e) {

        const _year = document.getElementById('year').value
        const _month = document.getElementById('month').value
        const _day = document.getElementById('day').value

        setYear(_year)
        setMonth(_month)
        setDay(_day)




        const newDate = new Date(_year, _month, _day);
        props.setValue(newDate)
        console.log(props.value)
    }
    // setDateInput({})
    return (
        <div class="create__right__form__group">

            <label
                for={props.id}
                className="create__right__form__label"
            >
                {props.label}
            </label>




            <div className="create__right__form__group__inputbox">

                <div className="create__right__form__input__icon">
                    {props.children}
                </div>



                {
                    /*
                    
                
                    <DatePicker
                        onChange={props.setValue}
                        value={props.value}
                        className={`create__right__form__input ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                    />
                    */
                }

                <div
                    className={`create__right__form__input create__right__form__input__date ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                >
                    <select

                        onChange={handleYearChange}
                        value={year}
                        id="year"
                        name="year"


                    >
                        <option hidden>Select Year</option>
                        {
                            years.map((item, index) => <option value={item}>{item}</option>)
                        }
                    </select>

                    <select

                        onChange={handleMonthChange}
                        value={month}
                        id="month"
                        name="month"

                    >
                        <option hidden>Select Month</option>
                        {
                            months.map((item, index) => <option value={(index)}>{item}</option>)
                        }
                    </select>

                    <select

                        onChange={handleDayChange}
                        value={day}
                        id="day"
                        name="day"

                    >
                        <option hidden>Select Day</option>
                        {

                            daysOfMonth.slice(0, daysLength).map((item, index) => <option value={item}>{item}</option>)

                        }
                    </select>
                </div>


            </div>

        </div>
    )
}

function CheckBoxInput(props) {

    return (
        <div class="create__right__form__group__checkbox">

            <input
                type="checkbox"
                id={props.id}
                name={props.id}
                className="create__right__form__input__checkbox"
                value={props.checkTrueInfo}
                onChange={() => props.setValue(!props.value)}
            />

            <label
                for={props.id}
                className="create__right__form__label__checkbox"
            >
                {props.label}
            </label>

        </div>
    )
}

function CheckBoxGroup(props) {
    return (
        <div class="create__right__form__group__checkboxgroup">
            <label
                for={props.id}
                className="create__right__form__label__checkbox"
            >
                {props.label}
            </label>
            {
                props.list.map((item, index) =>
                    <div class="create__right__form__group__checkbox create__right__form__group__checkbox--wrap">

                        {
                            props.expectedNo === props.value.length ?
                                <input
                                    type="checkbox"
                                    id={`${props.checkboxesid}-${index}`}
                                    name={props.checkboxesid}
                                    className="create__right__form__input__checkbox"

                                    checked={props.value.includes(item)}
                                    onChange={(e) => props.setValue(e, item)}
                                /> :
                                <input
                                    type="checkbox"
                                    id={`${props.checkboxesid}-${index}`}
                                    name={props.checkboxesid}
                                    className="create__right__form__input__checkbox"
                                    onChange={(e) => props.setValue(e, item)}
                                />
                        }


                        <label
                            for={`${props.checkboxesid}-${index}`}
                            className="create__right__form__label__checkbox"
                        >
                            {item}
                        </label>

                    </div>
                )
            }

        </div>
    )
}

function InputArea(props) {

    const { errorInput, setErrorInput } = useContext(AppDataContext)

    return (
        <div class="create__right__form__group">

            <label
                for="threatdesc"
                className="create__right__form__label"

            >
                {props.label}
            </label>

            <div className="create__right__form__group__inputbox create__right__form__group__inputbox--textarea">

                <div className="create__right__form__input__icon">
                    {props.children}
                </div>


                <textarea
                    className={`create__right__form__input create__right__form__textarea ${errorInput === props.id ? 'create__right__form__input__error' : ''}`}
                    placeholder={props.placeholder}
                    id={props.id}
                    name={props.id}
                    value={props.value}
                    onChange={(e) => props.setValue(e.target.value)}
                />

            </div>
        </div>
    )
}

export { Input, LetterInput, InputArea, DateInput, CheckBoxInput, CheckBoxGroup, Select }