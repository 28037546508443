import "rodal/lib/rodal.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import Navigator from "./Navigator";
import { AppDataProvider } from "./Contexts/AppDataContext";
import "./sass/main.scss";
import "./App.scss";

function App() {
  return (
    <AppDataProvider>
      <div className="App">
        <Navigator />
      </div>
    </AppDataProvider>
  );
}

export default App;
