import React from 'react'
import { MdSignLanguage } from 'react-icons/md'
import { FaPenToSquare, FaHandcuffs } from "react-icons/fa6";
import { GiHandcuffs, GiDecapitation, GiPoliceOfficerHead } from "react-icons/gi";
import { IoDocumentLock } from "react-icons/io5";
import { Select, InputArea } from '../shared/Inputs';
const YESNO = ['YES', 'NO']

function CriminalHistory(props) {
    return (
        <form action="#" className="create__right__form__element" onSubmit={(e) => { this.handleSubmit(e) }}>

            <Select
                id="threat"
                label="Have you ever been involved in activities that could be
                    considered a threat to the security of the state?"
                value={props.threat}
                setValue={props.setThreat}
                placeholder="Select Option"
                list={YESNO}
            >
                <FaHandcuffs />
            </Select>


            {
                props.threat === "YES" &&
                <InputArea
                    id="threatDesc"
                    label="Please give full details including dates"
                    value={props.threatDesc}
                    setValue={props.setThreatDesc}
                    placeholder="Provide full details with dates"
                >
                    <FaPenToSquare />
                </InputArea>

            }

            <Select
                id="fraud"
                label="Have you ever been convicted of fraud or dishonesty,
                    whether in a personal or professional capacity?"
                value={props.fraud}
                setValue={props.setFraud}
                placeholder="Select Option"
                list={YESNO}
            >
                <GiDecapitation />
            </Select>

            {

                props.fraud === "YES" &&
                <InputArea
                    id="fraudDesc"
                    label="Please give full details including dates"
                    value={props.fraudDesc}
                    setValue={props.setFraudDesc}
                    placeholder="Provide full details with dates"
                >
                    <FaPenToSquare />
                </InputArea>
            }

            <Select
                id="crime"
                label="Have you ever been convicted of a crime involving moral
                    turpitude, such as offenses that reflect on your character
                    or integrity?"
                value={props.crime}
                setValue={props.setCrime}
                placeholder="Select Option"
                list={YESNO}
            >
                <GiHandcuffs />
            </Select>



            {
                props.crime === "YES" &&

                <InputArea
                    id="crimeDesc"
                    label="Please give full details including dates"
                    value={props.crimeDesc}
                    setValue={props.setCrimeDesc}
                    placeholder="Provide full details with dates"
                >
                    <FaPenToSquare />
                </InputArea>
            }

            <Select
                id="revokation"
                label="Have you ever had a professional license revoked or
                    suspended due to criminal convictions involving the
                    security of the state, fraud, dishonesty, or moral
                    turpitude?"
                value={props.revokation}
                setValue={props.setRevokation}
                placeholder="Select Option"
                list={YESNO}
            >
                <IoDocumentLock />
            </Select>

            {
                props.revokation === "YES" &&

                <InputArea
                    id="revokationDesc"
                    label="Please give full details including dates"
                    value={props.revokationDesc}
                    setValue={props.setRevokationDesc}
                    placeholder="Provide full details with dates"
                >
                    <FaPenToSquare />
                </InputArea>
            }

            <Select
                id="charges"
                label="Are there any pending criminal charges against you for
                    offences related to the security of the state, fraud,
                    dishonesty, or moral turpitude?"
                value={props.charges}
                setValue={props.setCharges}
                placeholder="Select Option"
                list={YESNO}
            >
                <GiPoliceOfficerHead />
            </Select>

            {
                props.charges === "YES" &&

                <InputArea
                    id="chargesDesc"
                    label="Please give full details including dates"
                    value={props.chargesDesc}
                    setValue={props.setChargesDesc}
                    placeholder="Provide full details with dates"
                >
                    <FaPenToSquare />
                </InputArea>
            }
        </form>
    )
}

export default CriminalHistory