const COUNTRIES = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", 
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", 
    "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", 
    "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", 
    "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", 
    "Colombia", "Comoros", "Congo (Congo-Brazzaville)", "Costa Rica", "Croatia", "Cuba", "Cyprus", 
    "Czechia (Czech Republic)", "Democratic Republic of the Congo", "Denmark", "Djibouti", 
    "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", 
    "Eritrea", "Estonia", "Eswatini (fmr. Swaziland)", "Ethiopia", "Fiji", "Finland", "France", 
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", 
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", 
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", 
    "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", 
    "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", 
    "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico", 
    "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", 
    "Myanmar (formerly Burma)", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", 
    "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan", 
    "Palau", "Palestine State", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", 
    "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", 
    "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", 
    "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", 
    "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", 
    "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", 
    "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", 
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", 
    "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", 
    "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];

const REGIONS = [
    "Greater Accra",
    "Ashanti",
    "Central",
    "Eastern",
    "Western",
    "Northern",
    "Upper East",
    "Upper West",
    "Bono",
    "Ahafo",
    "Savannah",
    "North East",
    "Oti",
    "Volta",
    "Western North",
    "Bono East"
  ];

  const REALESTATEMOCKDATA = [
    "Royal Properties",
    "Prime Estate",
    "Gold Homes",
    "Platinum Realty",
    "Diamond Land",
    "Emerald Investments",
    "Sapphire Group",
    "Ruby Holdings",
    "Topaz Properties",
    "Pearl Estate",
    "Royal Homes",
    "Prime Realty",
    "Gold Land",
    "Platinum Investments",
    "Diamond Group",
    "Emerald Holdings",
    "Sapphire Properties",
    "Ruby Estate",
    "Topaz Homes",
    "Pearl Realty"
  ];

  const REGIONS_DISTRICT = {
    
      "Ahafo Region": ["Ahafo Regional Coordinating Council", "Asunafo South District Assembly", "Tano South Municipal Assembly", "Asutifi North District Assembly", "Tano North District Assembly", "Asutifi South District Assembly", "Asunafo North Municipal Assembly"],
      "Ashanti Region": ["Ashanti Regional Coordinating Council", "Afigya-Kwabre North District Assembly", "Ahafo-Ano South West District Assembly", "Amansie West District Assembly", "Asokore Mampong Municipal Assembly", "Atwima Nwabiagya North District Assembly", "Bosomtwe District Assembly", "Kumasi Metropolitan Assembly", "Obuasi East District Assembly", "Oforikrom Municipal Assembly", "Sekyere East District Assembly", "Adansi Asokwa District Assembly", "Afigya-Kwabre South District Assembly", "Akrofuom District Assembly", "Asante Akim Central Municipal Assembly", "Asokwa Municipal Assembly", "Atwima Nwabiagya South Municipal Assembly", "Ejisu Municipal Assembly", "Kwabre East Municipal Assembly", "Obuasi Municipal Assembly", "Old Tafo Municipal Assembly", "Sekyere Kumawu District Assembly", "Adansi North District Assembly", "Ahafo-Ano North Municipal Assembly", "Amansie Central District Assembly", "Asante Akim North Municipal Assembly", "Atwima Kwanwoma District Assembly", "Bekwai Municipal Assembly", "Juabeng Municipal Assembly", "Kwadaso Municipal Assembly", "Offinso Municipal Assembly", "Sekyere Afram Plains District Assembly", "Sekyere South District Assembly", "Adansi South District Assembly", "Ahafo-Ano South East District Assembly", "Amansie South District Assembly", "Asante Akim South Municipal Assembly", "Atwima Mponua District Assembly", "Bosome Freho District Assembly", "Ejura-Sekyedumase Municipal Assembly", "Mampong Municipal Assembly", "Offinso North District Assembly", "Sekyere Central District Assembly", "Suame Municipal Assembly"],
      "Bono Region": ["Bono Regional Coordinating Council", "Dormaa Central Municipal Assembly", "Berekum East Municipal Assembly", "Banda District Assembly", "Wenchi Municipal Assembly", "Sunyani West Municipal Assembly", "Jaman North District Assembly", "Berekum West District Assembly", "Sunyani Municipal Assembly", "Dormaa West District Assembly", "Jaman South Municipal Assembly", "Tain District Assembly", "Dormaa East District Assembly"],
      "Bono East Region": ["Bono East Regional Coordinating Council", "Atebubu Amantin Municipal Assembly", "Nkoranza North District Assembly", "Sene West District Assembly", "Kintampo North Municipal Assembly", "Kintampo South District Assembly", "Techiman Municipal Assembly", "Pru West District Assembly", "Sene East District Assembly", "Nkoranza South Municipal Assembly", "Pru East District Assembly", "Techiman North District Assembly"],
      "Central Region": ["Central Regional Coordinating Council", "Cape Coast Metropolitan Assembly", "Upper Denkyira-East Municipal Assembly", "Asikuma-Odoben-Brakwa District Assembly", "Upper Denkyira-West District Assembly", "Twifo Hemang Lower Denkyira District Assembly", "Komenda-Edina-Eguafo-Abirem Municipal Assembly", "Agona West Municipal Assembly", "Abura-Asebu Kwamankese District Assembly", "Gomoa East District Assembly", "Twifo Ati-Mokwa District Assembly", "Assin North District Assembly", "Assin Foso Municipal Assembly", "Awutu Senya East Municipal Assembly", "Ajumako-Enyan-Essiam District Assembly", "Gomoa West District Assembly", "Awutu Senya West District Assembly", "Effutu Municipal Assembly", "Mfantsiman Municipal Assembly", "Assin South District Assembly", "Agona East District Assembly", "Ekumfi District Assembly", "Gomoa Central District Assembly"],
      "Eastern Region": ["Eastern Regional Coordinating Council", "New Juaben South Municipal Assembly", "Birim Central Municipal Assembly", "Nsawam Adoagyiri Municipal Assembly", "Kwaebibirem Municipal Assembly", "Fanteakwa North District Assembly", "Upper Manya Krobo District Assembly", "Birim North District Assembly", "Upper West Akim District Assembly", "New Juaben North Municipal Assembly", "Kwahu West Municipal Assembly", "Suhum Municipal Assembly", "Akwapim South Municipal Assembly", "Asuogyaman District Assembly", "Atiwa West District Assembly", "Denkyembour District Assembly", "Ayensuano District Assembly", "Abuakwa South Municipal Assembly", "Akuapem North Municipal Assembly", "West Akim Municipal Assembly", "Kwahu South Municipal Assembly", "Birim South District Assembly", "Akyemansa District Assembly", "Kwahu Afram Plains North District Assembly", "Abuakwa North Municipal Assembly", "Lower Manya Krobo Municipal Assembly", "Yilo Krobo Municipal Assembly", "Fanteakwa South District Assembly", "Atiwa East District Assembly", "Kwahu East District Assembly", "Kwahu Afram Plains South District Assembly", "Okere District Assembly", "Asene Manso Akroso District Assembly", "Achiase District Assembly"],
      "Greater Accra Region": ["Greater Accra Regional Coordinating Council", "Accra Metropolitan Assembly", "Krowor Municipal Assembly", "Ga Central Municipal Assembly", "Tema Metropolitan Assembly", "Adentan Municipal Assembly", "Ga South Municipal Assembly", "Tema West Municipal Assembly", "Ashaiman Municipal Assembly", "La Dade-Kotopon Municipal Assembly", "Ledzokuku Municipal Assembly", "La Nkwantanang-Madina Municipal Assembly", "Ga East Municipal Assembly"],
      "Northern Region": ["Northern Regional Coordinating Council", "Sagnarigu Municipal Assembly", "Karaga District Assembly", "Kumbungu District Assembly", "Nanton District Assembly", "Tamale Metropolitan Assembly", "Nanumba North Municipal Assembly", "Kpandai District Assembly", "Mion District Assembly", "Yendi Municipal Assembly", "Gushegu Municipal Assembly", "Saboba District Assembly", "Tatale Sanguli District Assembly", "Savelugu Municipal Assembly", "Nanumba South District Assembly", "Zabzugu District Assembly", "Tolon District Assembly"],
      "North East Region": ["North East Regional Coordinating Council", "East Mamprusi Municipal Assembly", "West Mamprusi Municipal Assembly", "Chereponi District Assembly", "Mamprugu-Moagduri District Assembly", "Bunkpurugu-Nakpanduri District Assembly", "Yunyoo-Nasuan District Assembly"],
      "Oti Region": ["Oti Regional Coordinating Council", "Krachi East Municipal Assembly", "Guan District Assembly", "Nkwanta South Municipal Assembly", "Biakoye District Assembly", "Kadjebi District Assembly", "Krachi West Municipal Assembly", "Krachi Ntsumuru District Assembly", "Nkwanta North District Assembly", "Jasikan Municipal Assembly"],
      "Upper East Region": ["Upper East Regional Coordinating Council", "Binduri District Assembly", "Bawku Municipal Assembly", "Builsa South District Assembly", "Pusiga District Assembly", "Bolgatanga Municipal Assembly", "Bolgatanga East District Assembly", "Garu District Assembly", "Talensi District Assembly", "Kassena Nankana East Municipal Assembly", "Bongo District Assembly", "Kassena Nankana West District Assembly", "Tempane District Assembly", "Bawku West District Assembly", "Builsa North District Assembly", "Nabdam District Assembly"],
      "Upper West Region": ["Upper West Regional Coordinating Council", "Wa Municipal Assembly", "Nandom Municipal Assembly", "Lambussie/Karni District Assembly", "Jirapa Municipal Assembly", "Sissala West District Assembly", "Daffiama-Bussie-Issa District Assembly", "Lawra Municipal Assembly", "Wa East District Assembly", "Nadowli-Kaleo District Assembly", "Sissala East Municipal Assembly", "Wa West District Assembly"],
      "Volta Region": ["Volta Regional Coordinating Council", "Ketu South Municipal Assembly", "Keta Municipal Assembly", "North Dayi District Assembly", "Agortime Ziope District Assembly", "Adaklu District Assembly", "Hohoe Municipal Assembly", "Ketu North Municipal Assembly", "Central Tongu District Assembly", "North Tongu District Assembly", "Anloga District Assembly", "Kpando Municipal Assembly", "Akatsi South Municipal Assembly", "South Tongu District Assembly", "Akatsi North District Assembly", "Ho Municipal Assembly", "South Dayi District Assembly", "Afadzato-South District Assembly", "Ho West District Assembly"],
      "Western Region": ["Western Regional Coordinating Council", "Sekondi-Takoradi Metropolitan Assembly", "Prestea-Huni Valley Municipal Assembly", "Effia-Kwesimintsim Municipal Assembly", "Wassa East District Assembly", "Tarkwa-Nsuaem Municipal Assembly", "Wassa Amenfi West Municipal Assembly", "Ellembelle District Assembly", "Mpohor District Assembly", "Nzema East Municipal Assembly", "Wassa Amenfi East Municipal Assembly", "Shama District Assembly", "Jomoro Municipal Assembly", "Ahanta West Municipal Assembly", "Wassa Amenfi Central District Assembly"],
      "Western North Region": ["Western North Regional Coordinating Council", "Aowin Municipal Assembly", "Bia East District Assembly", "Sefwi Akontombra Assembly", "Sefwi Wiawso Municipal Assembly", "Juaboso District Assembly", "Bibiani-Anhwiaso-Bekwai Municipal Assembly", "Bodi District Assembly", "Bia West District Assembly", "Suaman District Assembly"],
      "Savannah Region": ["Savanna Regional Coordinating Council", "Central Gonja District Assembly", "East Gonja Municipal Assembly", "North Gonja District Assembly", "West Gonja Municipal Assembly", "Bole District Assembly", "Sawla Tuna Kalba District Assembly", "North East Gonja District Assembly"]
  };

export { COUNTRIES, REGIONS_DISTRICT, REALESTATEMOCKDATA }